@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

:root{
  --p-color:#6C757D; 
  --h-color:#343A40;
  --green:#2D995F;
  --white: #ffffff;
}
.mobilMobileNav{
  display: none;
}
.webMobileNav{
  display: none;
}

.contCont{
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 10%; */
}
.aboutHeader1{
  display: flex;
  /* margin-top: 2rem; */
  flex-direction: row;
gap: 40px;
  margin-bottom: 5rem;
  
}
.imageText{
  display: flex;
  flex-direction: column;
/* align-items: center; */
/* justify-content: center; */
 
}
.bookCont{
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* justify-content: center; */
}
.bookCont1{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bookCont1 h1{
  color: #343A40;
  font-size: 14px;
  font-weight: 700;
}
.bookCont1 p{
  color: #2D995F;
  font-size: 14px;
  font-weight: 400;
}
.mobileHeroCont{
  display: none;
}
.generalBody{
  overflow: hidden;
  font-family: inter;
}

p{
  color: var(--p-color);
}
h1{
  font-size: 64px;
}
 h2, h3, h4, h5, h6{
  /* color: var(--h-color); */
}
.recent{
  color: #000 !important;
}
.recent12{
  color: #fff !important;
  text-align: left;
}
/* button{ */
.btnss{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 31px;
  font-weight: 700;
  cursor: pointer;
}
.generalPadding{
  padding-top: 103px;
}
.wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapperBackground{
    background-image: url('../../assets/urban/urbanBg.png');
    background-size: cover;
    margin-top: 0px;
    padding-bottom: 50px;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
}
.videoSection{
    display: flex;
    justify-content: center;
    padding: 30px 30px 30px 30px;
}
.wrapperBackground h1{
    margin-top: 10rem;
    text-align: center;
    color: #343A40;
    font-weight: 900;
    font-size: 50px;
    padding-left: 10%;
    padding-right: 10%;
}
.subHeaderText{
    text-align: center;
    font-size: 15px;
    color: #6C757D;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 36px;
    padding-left: 10%;
    padding-right: 10%;
    -webkit-line-clamp: 3;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

}
.noDetails{
  color: black;
}
.hero{
  width: 100%;
  /* background-image: url('../../assets/carousel\ 4.svg'); */
  height: 680px;
  /* background-size: contain; */
  color: var(--white);
  background-repeat: no-repeat;
  display: flex;
  /* justify-content: center; */
  background-position: center;
  animation: changeBackground 40s infinite;
  /* align-items:center; */
}
.bottomContainer{
  margin-top: 3rem;
 
 
}
.obtain{
  font-size: 28px;
  color: #E95935;
  font-weight: 700;
  margin-left: 10px;
  height: 5%;
  margin-top: 48px;
  line-height: 33px;
}
.subObtain{
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  /* transition: ease 0.5s; */
  /* margin-top: 15px; */
}

.subObtain a{
  text-decoration: none;
  text-transform: none;
  color: #6C757D;
  text-align: left;
}

/* .groupedCont:hover{
  transform: scale(1.1);
} */

.obtain2{
  font-size: 28px;
  color: #2D995F;
  font-weight: 700;
  margin-left: 10px;
  height: 5%;
  margin-top: 48px;
  line-height: 33px;
}
.subObtain3{
  color: #D38E34;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: ease 0.5s;
  margin-top: 15px;
}

.subObtain3:hover{
  color: #2D995F;
  transform: scale(1.1);
}
.obtain3{
  font-size: 28px;
  color: #D38E34;
  font-weight: 700;
  margin-left: 10px;
  height: 5%;
  margin-top: 48px;
  line-height: 33px;
}
.subObtain4{
  color: #5D5FEF;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: ease 0.5s;
  margin-top: 15px;
}

.subObtain4:hover{
  color: #2D995F;
  transform: scale(1.1);
}
.obtainfur{
  font-size: 28px;
  color: #5D5FEF;
  font-weight: 700;
  margin-left: 10px;
  height: 5%;
  margin-top: 48px;
  line-height: 33px;
}

.subObtain5{
  color: #EF5DA8;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: ease 0.5s;
  margin-top: 15px;
}

.subB .subObtain5 {
  margin-top: 45px;
}

.subObtain5:hover{
  color: #2D995F;
  transform: scale(1.1);
}
.obtain5{
  font-size: 28px;
  color: #EF5DA8;
  font-weight: 700;
  margin-left: 10px;
  height: 5%;
  margin-top: 48px;
  line-height: 33px;
}
.subObtain2{
  color: #2D995F;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: ease 0.5s;
  margin-top: 15px;
}

.subObtain2:hover{
  color: #2D995F;
  transform: scale(1.1);
}
.subB{
  /* margin-left: 10px; */
  margin-top: 40px;
}
.subB5{
  margin-left: 10px;
  margin-top: 100px;
}
.subB4{
  margin-left: 10px;
  margin-top: 100px;
}

.subText{
  /* border: 1px solid black; */
  /* margin-top: 30px; */
  /* height: 30%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* position: absolute; */
  /* top: 90%;  */
  /* padding-left: 60%; */
  /* left: 0%; */
}

.headkad {
display: flex;
justify-content: center;
flex-direction: row;
align-items: center;
width: 100%;
/* margin-top: 2rem; */
}
.firstBox{
width: 50%;
background-color: #F5F5F5;
padding-left: 5rem;
padding-bottom: 2rem;
/* height: 300px; */
padding-top: 5rem;
}
.secondBox{
width: 50%;
background-color: #2D995F;
padding-left: 5rem;
/* height: 300px; */
padding-top: 5rem;
padding-bottom: 2rem;
}
.firstBox h1{
    font-size: 50px;
    font-weight: 900;
    color:  #2D995F;
}
.firstBox p{
    font-size: 18px;
    font-weight: 400;
    color:  #6C757D;
    padding-right: 40px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.secondBox p{
    font-size: 18px;
    font-weight: 400;
    color:  #fff;
    padding-right: 40px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
}
.secondBox h1{
    font-size: 50px;
    font-weight: 900;
    color:  white;
}
.aboutSection{
  margin-top: 3rem; 
  display: flex; 
  justify-content: center;
}

.aboutText h1{
text-align: center;
color: #000000;
font-size: 36px;
font-weight: 700;

}
.aboutText p{
text-align: center;
font-size: 15px;
color: #343A40;

}
.cardItems p{
  margin-top: 20px;
  font-size: 18px !important;
  color: #343A40 !important;
}
.aboutHeader{
    display: flex;
    margin-top: 2rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin-bottom: 5rem;
}
.aboutPicture{
    width: 50%;
    padding-left: 5rem;
   
}
.aboutPicText{
  padding-right: 90px;
  font-size: 16px;
}
.aboutPicText p{
  color: #000000 !important;
}
.bioSection{
    justify-content: center;
    background-color: #164B2E;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 5rem;
}
.contactSection{
    justify-content: center;
    background-color: #164B2E;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 5rem;
}
.contactContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-top: 10%;
  padding-right: 10%;
  padding-bottom: 20px;
  width: 100%;
}

.contact1{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.contact12{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 30%;
}
.contact1 h1{
  font-size: 67px;
  color: #fff;
  font-weight: 700;
}
.contact1 p{
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}
.contact12 p{
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.xplore {
  font-weight: 700;
  font-size: 36px;
  font-family: 'inter';
  margin-top: 41px;
  color: black;
}
.ogunInvestment5{
  background-image: url('../../assets/carousels/ir2.png');
  background-size: cover;
  margin-top: 0px;
  padding-bottom: 50px;
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;
}
.xploretxt {
  font-weight: 400;
  font-size: 20px;
  font-family: 'inter';
  text-align: center;
  margin-bottom: 3rem;
  color: #000;
}
.bioText h1{
padding-top: 5rem;
color: white;
font-size: 36px;
font-weight: 700;
text-align: center;
}
.videoText h1{
padding-top: 5rem;
color: white;
font-size: 36px;
font-weight: 700;
text-align: center;
}
.videoBottom{
    display: flex;
    justify-content: center;
}
.videoBottom h1{
color: #2D995F;
font-size: 18px;
font-weight: 700;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
background-color: white;
width: 228px;
height: 46px;
border-radius: 4px;
cursor: pointer;
}
.videoBottom h1:hover{
color: white;
background-color: #2D995F;
}
.videoBottom1{
    display: flex;
    justify-content: center;
    margin-top: -70px;
    margin-bottom: 30px;
}
.videoBottom1 h1{
color: #fff;
font-size: 18px;
font-weight: 700;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
background-color: #2D995F;
width: 228px;
height: 46px;
border-radius: 4px;
cursor: pointer;
}
.videoBottom1 h1:hover{
color: white;
background-color: #144d2f;
}
.bioText p{
color: white;
font-size: 12px;
font-weight: 500;
text-align: center;
}
.bioUpdate{
    background-color: #22724747;
    /* max-height: 700px;
     overflow-y: scroll; */
    padding: 50px 30px 30px 30px;
    width: 50%;
}
.imageBio{
 object-fit:block;
  height: 400px;
   width: 500px; 
}
.mobileDisplay{
  display: none;
}
.bioUpdate p{
  order: 1;
    color: white;
    font-size: 14px;
}
.bioContainer{
    padding-left: 5rem;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    /* align-items: center; */
     margin-top: 3rem;
    
}
.bioCont1{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
}
.bioCont1 h1{
    font-size: 26px;
    text-align: center;
    color: #ffffff;
    font-weight: 700;
}
.bioCont1 p{
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
}
.photoSection{
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding-left: 5rem;
    width: 100%;
    
    
}
.photoLine{
    height: 100%;
    width: 5px;
   background-color: #ADB5BD;
}
.photoContainer{
display: flex;
flex-direction: row;
gap: 1rem;
}
.photoSect{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.photoSect p{
  color: #000;
}

.photoGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    width: 50%;
 margin-left: 7rem;
  }
  
 .bottomButton{
    margin-top: 20px;
    border-radius: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #2D995F;
    align-items: center;
    width: 200px;
    height: 46px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

 }
 .bottomButton:hover{
background-color: #1e613d;
color: #2D995F;
 }

 .bottom{
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
 }
  
  .photoItem img {
    width: 400px;
    height: 300px;
    display: block;
  }
  
  .photoDescription {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 700;
  }
  .photoDescription1 {
    /* margin-top: 10px; */
    font-size: 12px;
    /* font-weight: 500; */
    color: #6C757D;
  }
  .photoDescription12 {
    /* margin-top: 10px; */
    font-size: 12px;
    /* font-weight: 500; */
    color: #fff;
  }
  
  .photoSubtext {
    font-size: 12px;
    color: #6C757D;
  }
  .videoContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 50px; 
    margin-top: 2rem;
    padding: 50px 50px 50px 100px;
    justify-content: center;
    align-items: center;
  }
  /* .noVid{
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .videoItem{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 315px; 
    margin-bottom: 40px;
  }
  .videoItem iframe {
    width: 100%;
    height: 315px; 
    border: 3px solid #164B2E;
    /* margin-bottom: 20px; */
  }
  
  .videoDescription {
    margin-top: 10px;
    font-size: 15px;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
  }
  

.iconBG{
  width:50px;
  height:50px;
  margin-top: 24px;
}
.subContainer{
 
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  border: 1px solid #ACE7C7;
  padding: 10px;
}

.bgihero {
  background-image: url('../../assets/landingpage/back.png');
  width: 100%;
  margin-bottom: 30px;
  /* height: 754px; */
}

@keyframes changeBackground {
  0% {
    background-image: url('../../assets/carousel\ 4.svg');
  }
  25% {
    background-image: url('../../assets/carousel2.svg');
  }
  50% {
    background-image: url('../../assets/carousel3.svg');
  }
  75% {
    background-image: url('../../assets/carousel4.svg');
  }
  100% {
    background-image: url('../../assets/carousel\ 4.svg');
  }
}

.heroCont{
  max-width: 1191px;
  margin-top: 15rem;
  margin-left: 4rem;
  /* text-align: center; */
  height: 200px;
}
.heroCont h1{
  font-weight: 900;
  line-height: 4rem;
  /* max-width: 467px; */
  /* padding: 0 300px; */
}
.heroCont p{
  font-size: 20px;
  margin: 11px 0 20px 0;
  color: var(--white);
}
.heroBtns{
  color: var(--green);
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
}
.heroBtns:hover{
  color: #05552a;
}
.threeCardsbg{
  background-image: url('../../assets/landingpage/back.png');
  background-size: cover;
  margin-top: 30px;
  padding-bottom: 50px;
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 50px;

}
.threeCards{
  display: flex;
  /* justify-content: space-around; */
  gap: 20px;
  align-items: center;
  /* padding: 0 100px; */
  /* margin-top: -50px; */
  cursor: pointer;
}
.threeCards h2{
  color: var(--green);
  font-size: 26px;
  font-weight: 700;
}

.threeCards h2:hover{
  color: var(--white);
}
.sectionThree{
  background-image: url('../../assets/landingpage/fact.png');
  height: 600px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -50px;
}
.sectionFour{
  background-image: url('../../assets/landingpage/iseya.png');
  height: 658px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.greenBtn{
  background-color: var(--green);
  color: var(--white);
  /* font-weight: 700; */
}
.greenBtn:hover,
.pricingBtns:hover{
  background-color: #709b84;
}
.heroGreenBtn{
  padding: 16px 63px;
}
.heroAmounts{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 146px;
  margin: 25px 0 0 45px ;
}
.heroAmounts h1{
  font-size: 64px;
  font-weight: 300;
}
.container{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 20px;
}
.container .automateCont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
}
.bgcol{
  background-color: #F7FDF9;
}
.container .automateCont .cont{
  max-width: 550px;
}
.thickgreen{
  width: 116px;
  height: 17px;
  background-color: #2D995F;
  margin-bottom: 32px;
}

.container .automateCont h1{
  font-size: 56px;
  font-weight: 800;
  color: var(--h-color);
  /* padding-right: 5rem; */
}

.container .automateCont p{
  font-size: 16px;
  font-weight: 400;
  color: #6C757D;
  /* margin-top: -30px; */
  /* white-space: nowrap; */
}

.sectionSix{
  background-image: url('../../assets/landingpage/sectors.png');
  height: 990px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin-bottom: 5px; */
}
.sectionEight {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.2s, transform 2s;
}

.sectionEight.active {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.text-animation {
  animation: slideInFromRight 5s ease-out forwards; /* Increase duration to 1s */
  animation-delay: 0.5s;
}

.sectionSeven{
  background-image: url('../../assets/landingpage/banng.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* margin-top: 5px; */

}

.investBanner{
  text-align: center;
  font-size: 300px;
  color: white;
  font-weight: 900;
  margin-top: 50px;
}
.investBanner1{
  text-align: center;
  font-size: 48px;
  color: white;
  font-weight: 800;

}

.sectionText{

  text-align: center;
}
.sectionButton{
  margin-bottom: 20px;
}
.sectionEight{
  background-image: url('../../assets/SectionEightBg.svg');
margin-top: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.sectionEightFlex{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
  justify-content: space-between;
}
.sectionEightFlex h6{
  font-size: 28px;
  color: #343A40;
  font-weight: 700;
}
.sectionEightFlex .div{
  max-width: 528px;
}
.sectionEightFlex .div small{
  font-size: 20px;
  color: #2D995F;
}
.sectionEightFlex .div p{
  font-size: 36px;
  font-weight: 700;
  /* color: #2D995F; */
}
.sectionEightFlex h2{
  color: var(--green);
  font-size: 44px;
  font-weight: 900;
}

.sectionNine{
  background-image: url('../../assets/sectionNineBg.svg');
  height: 1040px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.sectionNineCont{
  color: var(--white);
  max-width: 623px;
  text-align: center;
  margin-top: 58px;
  top: 10%;
  display: block;
}
.sectionNineCont h4{
  font-weight: 700;
  font-size: 24px;
  display: block;
}
.sectionNineCont h2{
  font-weight: 900;
  font-size: 54px;
  line-height: 65px;
  display: block;
}
.sectionTen{
  background-image: url('../../assets/sectionTentBg.svg');
  height: 800px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 92px 0;
  gap: 35rem;
  /* align-items: start; */
}
.sectionTen h3{
  color: #343A40;
  font-size: 36px;
  font-weight: 600;
}
.sectionTen button{
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  padding: 16px 64px;
}
.sectionEleven{
  width: 100%;
  position: relative;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  background-color: #ACE7C71A;
}
.sectionEleven .ogunMap{
  background-image: url('../../assets/ogunmap2.svg');
  background-size: contain;
  background-position: center;
  width: 557px;
  height: 402px;
  overflow: hidden;
  margin-bottom: -8.8rem;
  background-repeat: no-repeat;
  /* z-index: 999; */
  margin-top: -5rem;
}
.sectionEleven .ogunMap h2{
  font-size: 45px;
  font-weight: 900;
  margin-top: 12rem;
  padding-left: 30px;
  /* margin-right: 1rem; */
}

.newsSection{
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  padding: 32px 0;
  gap: 25px;
  background-color: #ACE7C71A;
  /* margin-top: 20rem; */
 
}

.newsSection h3{
  color: #343A40;
  font-size: 36px;
  font-weight: 600;
}
.newsSection button{
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  padding: 16px 64px;

}
.newsGroup{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 100%;
padding-left: 20px;
padding-right: 20px;
}


.newsContainer{
  background-color: #fff;
  width: 70%;
  height: 100%;

  box-shadow: 0px 4px 20px 0px rgba(220, 220, 220, 0.25);
  border-radius: 8px;
  
}

.actionP{
  font-size: 16px;
   color: "#2D995F";
   cursor: pointer;
}
.actionP :hover{
  color: #0a4b28;
}

.automateImg{
  /* width: 100%; */
  max-width: 816px;
  overflow: hidden;
  height: 738px;
}

.imgs{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.newsContainer p{
  font-size: 14px;
  color: #343A40;
  font-weight: 400;
  text-align: center;
}
.newsData1{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.newsData2{
  background-color: white;
  padding-left: 18px;
  padding-top: 18px;
  padding-right: 18px;
  /* width: 100%; */
  /* height: 130px; */
  /* padding-bottom: 18px; */
}
.newsData2 h1{
font-size: 16px;
color: #343A40;
font-weight: 700;
-webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
.newsData2 p{
font-size: 10px;
color: #6C757D;
font-weight: 300;
-webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.newsData2 h1:hover{
  color: #2D995F;
}
.newsBelow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;

}
.newsBelow p{
  font-size: 10px;
  color: #6C757D;
  font-weight: 300;
  }
.newsBelow h1{
  font-size: 12px;
  color: #2D995F;
  font-weight: 700;
  cursor: pointer;
  }
.newsBelow h1:hover{
  font-size: 12px;
  color: #4be090;
  font-weight: 700;
  cursor: pointer;
  }
.cards{
  border-radius: 10px;
  height: 300px;
  width: 100%;
  padding: 33px 30px;
}

.cardText{
  margin-top: 30px;
}

.tick{
  width: 20px;
  height: 20px;
}
.groupedCont{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 10px;
}

.cardItems h5{
  font-size: 18px;
  font-weight: 700;
  margin: 63px 0 22px 0;

}
.cardItems p{
  font-size: 12px;
  font-weight: 400;
}
.eclipse {
  width: 71px;
  height: 71px;
}
.womanimg {
  width: 400px;
  height: 245px;
  margin-top: 10rem;
}
.card1{
  background-color: rgba(255, 186, 8, 0.3);
}
.card2{
  background-color: rgba(172, 231, 199, 0.3);
}
.card3{
  background-color: rgba(253, 101, 132, 0.2);
}
.card4{
  background-color: var(--white);
  box-shadow: rgba(52, 177, 110, 0.09) 0px 6px 24px 0px, rgba(52, 177, 110, 0.09) 0px 0px 0px 1px;
  transition: ease 0.5s;
}

.card4:hover{
  transform: scale(1.1);
  box-shadow: 3px 5px 5px #34B16E17;
  background-color: #2D995F;
  color: white;
}

.card4:hover .cardItems h2,
.card4:hover .cardItems .groupedCont a {
  color: white;
}


.card5{
  background-color: #bdcde6;
}
.card6{
  background-color: rgba(172, 231, 199, 0.3);
}


.efficientCard {
  width: 33.3333333%;
  background-color: #F9F9F9;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:137px 28px 100px 77px; */
}
.contImg{
object-fit: contain;
 height: 200px;
}

.efficientCardGreen {
  width: 33.3333333%;
  background-color: #2D995F;
  height: 300px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:137px 28px 100px 77px; */
}
.efficientCardGreen .efficientxt{
  color: var(--white) !important;
}
.effiCont{
  max-width: 466px;
}
.efficientxt {
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 18px;
}

.uder {
  font-size: 14px;
  color: #6C757D;
  font-weight: 400;
}

.howItWorks {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 81px;
  /* padding-left: 50px; */
  /* padding-top: 81px; */
}
.howItWorksCont{
  max-width: 527px;
  /* padding-right: 9rem; */
}
.howItWorksTxt {
  font-size: 72px;
  font-weight: 800;
  color: #343A40;
}

.underhowItWorksTxt {
  color: #6C757D;
  font-size: 19px;
  padding: 24px 0 62px 0;
  /* margin-top: -80px; */
  /* font-family: inter; */
  font-weight: 400;
}

.options {
  display: flex;
}

.optsdeg {
  width: 315px;
  height: 239px;
  background-color: #343A40;

}

.herobtntr {
  display: none;
}

.optsdeg2 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  margin-left: 43px;

}

.optsdeg23 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  margin-left: 43px;
  margin-top: 32px;

}

.optsdeg3 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  /* margin-left: 43px; */
  margin-top: 32px;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  color: #343A40;
  font-size: 36px;
  /* text-align: center; */
  margin-left: 24px;
  margin-top: 28px;
  /* line-height: 60px; */
  font-weight: 700;
}

.si {
  font-weight: 700;
  font-size: 25px;
  color: #E9ECEF;
  margin-left: 35px;
  margin-top: 26px;
}

.si2 {
  font-weight: 700;
  font-size: 26px;
  color: #6C757D;
  margin-left: 35px;
  margin-top: 26px;
}

.underSi {
  font-size: 14px;
  font-weight: 400;
  color: #E9ECEF;
  margin-left: 36px;
  /* margin-top: -30px; */
}

.underSi2 {
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
  margin-left: 36px;
  /* margin-top: -30px; */
}

.mobile {
  /* margin-top: 152px; */
  /* margin-left: 81px; */
  width: 602px;
  height: 1221px;
  overflow: hidden;
}


.bgColor{
  background-color: rgba(172, 231, 199, 0.1);
  /* padding-bottom: 3rem; */
}
.pricingSection{
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.pricingSectionCont{
  text-align: center;
}
.pricingTxt {
  font-size: 36px;
  font-weight: 700;
  /* text-align: center; */
  /* margin-left: 50%; */
  padding-top: 83px;
}

.under_pricingTxt {
  font-size: 18px;
  font-weight: 400;
  color: #6C757D;
}

/* .pricingOpts {
  margin-top: 76px;
} */

.containerForPricing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-bottom: 3rem;

}

.pricingDesg {
  background-color: var(--white);
  position: relative;
  padding: 32px 34px;
  height: 620px;
  border-radius: 20px;
  /* padding-bottom: 20px; */
  box-shadow:2px 2px 5px rgba(5, 5, 5, 0.15);
}

.pricingDesg2 {
  background-color: #2D995F;
  /* background-color: pink; */
  /* width: 332px;
  height: 576px;
  border-radius: 20px;
  padding-bottom: 20px;
  box-shadow: rgba(172, 231, 199, 0.15); */
}
.imageee{
object-fit: cover;
height: 300px;
width: 500px
}

.pricingPad h4 {
  font-size: 24px;
  /* color: #343A40; */
  font-weight: 700;
}
.pricingPad2 h4 {
  font-size: 24px;
  color: var(--white);  
  font-weight: 700;
}
.pricingPad2 p{
  color: var(--white) !important;
} 
.pricingDesg2 button{
  background-color: var(--white);
  color: var(--green);
  transition: ease 0.5px;
}
.pricingDesg2 button:hover{
  background-color: transparent;
  border: 2px solid white;
  color: white;
}
.freePlan2 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

/* .pricingPad {
  padding-top: 32px;
  padding-left: 34px;
} */

.amount {
  font-size: 30px;
  font-weight: 700;
  color: #6C757D;
  margin-top: 39px;
}
.amountSpan {
  font-size: 14px;
  font-weight: 400;
}
.useFor {
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
  margin: 15px 0 26px 0;
}
.lists {
  /* padding-left: 5px; */
  color: #6C757D;
  font-size: 14px;
}
.subLists{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  gap: 20px;
  padding: 9px 0;
}
.subLists p{
  font-size: 14px;
  font-weight: 400;
}
.amount2 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 39px;
}


.useFor2 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: -20px;
}

.tick {
  width: 25px;
  height: 25px;
  /* padding-right: 20px; */
  /* margin-top: 10px; */
}

.tick2 {
  width: 25px;
  height: 25px;
  padding-right: 20px;
  margin-top: 10px;
}

.textUnderUsefor {
  padding-left: 26px;
  color: #6C757D;
  font-size: 14px;
}

.textUnderUsefor2 {
  padding-left: 26px;
  color: #ffffff;
  font-size: 14px;
}

.pricingBtns {
  width: 80%;
  height: 43px;
  color: #fff;
  background-color: #2D995F;
  border-radius: 4px;
  /* margin-left: 17px; */
  text-align: center;
  position: absolute;
  bottom: 20px;
  /* margin-top: 100px; */
  /* line-height: 40px; */
  transition: ease 0.5px;
}

/* .Tryfree2 {
  width: 298px;
  height: 43px;
  color: #2D995F;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 17px;
  text-align: center;
  margin-top: 5px;
  line-height: 40px;
} */

/* .Tryfree3 {
  width: 298px;
  height: 43px;
  color: #fff;
  background-color: #2D995F;
  border-radius: 4px;
  margin-left: 17px;
  text-align: center;
  margin-top: 200px;
  line-height: 40px;
} */

.faqcont{
  width: 100%;
  max-width: 900px;
  padding: 100px 0;
}
.faqsHead{
  text-align: center;
}
.faqsHead h1{
  font-size: 38px;
  font-weight: 800;
}
.faqsHead p{
  font-size: 20px;
  font-weight: 400;
  padding: 16px 0 54px 0;
}

.xplore {
  font-weight: 700;
  font-size: 36px;
  font-family: 'inter';
  /* margin-top: 81px; */
  color: black;
  text-align: center;
}
.xploretxt {
  font-weight: 400;
  font-size: 20px;
  font-family: 'inter';
  text-align: center;
  margin-bottom: 3rem;
}
.accordionContainer{
  /* margin-top: 66px; */
  width: 100%;
  max-width: 900px;
  margin-bottom: 70px;
}
.accCont{
  display: flex;
  justify-content: center;

}
.accordionSpace{
  margin-top: 30px;
  }

.faqcont{
  width: 100%;
  max-width: 900px;
  padding: 100px 0;
}
.faqsHead{
  text-align: center;
}
.faqsHead h1{
  font-size: 38px;
  font-weight: 800;
}
.faqsHead p{
  font-size: 20px;
  font-weight: 400;
  padding: 16px 0 54px 0;
}

.subContainer a {
  text-decoration: none;
  color: #E95935;
}
.subObtain2 a{
  text-decoration: none;
  color: #2D995F;
}
.subObtain4 a{
  text-decoration: none;
  color: #5D5FEF;
}

/* .frame1{
  width: 100%;
} */
.footerHero{
  width: 100%;
  /* height: 452px; */
  background-image: url("../../assets/Frame\ 91.png");
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 59px;
  padding-top: 95px;
  padding-bottom: 80px;
}
.footerHeroText{
  font-size: 76px;
  font-weight: 900;
  margin: 0;
  color: var(--white);
}
.btns{
  /* margin-top: 59px; */
  background-color: #ffffff;
  border: none;
  width: 188px;
  height: 44px;
  color: #2D995F;
  border-radius: 4px;
  transition: ease 0.5s;
  font-weight: 700;
}
.btns:hover{
  background-color: transparent;
  color: var(--white);
   border: 1px solid white;
}

.copy{
  line-height: normal;
  margin-top: 60px;
}
.name{
  font-size: 20px;
  font-weight: 700;
  font-family: 'inter';
  padding-bottom: 20px;
}
.FooterBg{
  background-color: #343A40;
  z-index: 1;
  /* height: 40px; */
}
.mainFooter{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  padding: 8rem  0 30px 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
}
.footerLogo{
  margin-bottom: 15px;
  cursor: pointer;
}
.footerLogoCont p{
  margin-left: 10px;
  font-weight: 400;
  /* font-size: 20px; */
}
.footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
}
.footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
}
.footerConts p{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  /* font-size: 18px; */
  font-weight: 400;
  font-family: 'inter';
  padding: 0;
  gap: 5px;
}
.footerContsnav{
  padding-left: 4rem;
}

.footerInput{
  background-color: #F2F5F3;
  /* border: none; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-top: 10px;
}
.footerInput input{
  background-color: transparent;
  /* padding: 10px 5px; */
  border: none;
  outline: none;
  font-size: 14px;
}
.footerInput img{
  cursor: pointer;
}
.footerText {
  background-color: #000;
  text-align: center;
 height: 100%;
 display: flex;
 flex-direction: column;
 /* border-top: 2px solid white; */
 padding-top: 20px;

}



.subFooter{
  color: white;
  margin-bottom: 3px;
}
.subFooter1{
  color: white;
 
}
.subFooter1{
  color: white;
  /* margin-top: -0px; */
}
/* :placeholder-shown{
  font-size: x-small;
  padding-left: 20px;
} */

.viewgallerymble {
  display: none;
}


/* .heroBtnsmble {
  display: none;
} */

.herobg {
  display: none;
}


.footerConts a{
text-decoration: none;
}



/* .webheader {
  display: block;
} */

.mbleheader {
  display: none !important;
}



@media (max-width: 992px) { 
  .mobileHeroCont{
    display: block;
    position: relative;
  }

  .newsContainer {
    display: none;
  }

  .xplore {
    font-weight: 700;
    font-size: 20px;
    font-family: 'inter';
    margin-top: 50px;
    color: black;
  }

  .xploretxt {
    font-weight: 400;
    font-size: 12px;
    font-family: 'inter';
    text-align: center;
  }

  .headkad {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -20px;
    }

  .newsData1{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 125px; */
    border-radius: 4px;
    
    gap: 10px;
  }


  .sectionThree{
    background-image: url('../../assets/landingpage/fact.png');
    height: 600px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 0;
  }


  .newsSection{
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    padding: 72px 0 62px 0;
    gap: 15px;
    background-color: #ACE7C71A;
    /* height: 900px; */
   
  }
  .newsSection button{
    color: var(--white);
    font-weight: 700;
    font-size: 12px;
    padding: 12px 51px;
    margin-top: 45rem;
  }
  .newsGroup{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* gap: 10px; */
    width: 100%;

  /* padding-left: 20px; */
  /* padding-right: 20px; */
  }

  .subB{
    margin-left: 10px;
    margin-top: 20px;
  }
  .subB5{
    margin-left: 10px;
    margin-top: 20px;
  }
  .subB4{
    margin-left: 10px;
    margin-top: 20px;
  }

  .iconBG{
    width:71px;
    height:71px;
    margin-top: 24px;
  }

  .subText{
   
    width: 100%;
    display: flex;
    flex-direction: column;
   
  }

  .absolute{
    
  }
  .mobileHeroContText{
    position: absolute;
    top: 40%;
    left: 5%;
    color: white;
  }
  .mobileHeroContText h4{
    font-size: 40px;
    font-weight: 900;
  }
  .mobileHeroContText h2{
    font-size: 14px;
    font-weight: 400;
    padding-right: 5%;
  }

  .heroslider {
    display: none;
  }

  .herobtntr {
    display: block;
    position: absolute; 
    top: 38% !important; 
    left: 25%; 
    transform: translate(-50%, -50%);
  }

  .wrapper .FooterBg {
padding-left: 5%;
  }

  .herobg {
    display: block;
    width: 100%;
    height:400px;
  }
  
  .heroBtns{
    /* display: none; */
  }
  .contact1 h1{
    font-size: 67px;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }
  .contact1 p{
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }
/* New Edit */
  .heroBtnsmble {
    /* margin: 0 0 3% 5%; */
    transform: translate(-50%, -50%); 
    color: var(--green);
    font-weight: 400;
    font-size: 12px;
    /* background-color: red; */
    /* margin: -60.5rem 0 0 50%; */
    margin: -36rem 0 0 50%;
    z-index: 999;
    display: block;
    /* position: absolute; */
    /* top: 244px; */
    /* left: 173px; */
    /* bottom: 20px; */

  }

  

 
body{
  overflow-x: hidden;
}

  /* .sectionNineCont2 {
    position: absolute; 
    top: 1090% !important; 
    left: 50%; 
    transform: translate(-50%, -50%);
  } */

  .sectionNineCont h4{
   display: none;
  }
  .sectionNineCont h2{
    display: none;
  }


  .sectionFour{
    background-image: url('../../assets/landingpage/iseyamb.jpg');
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 2.5rem 0;
  }

  .sectionThree{
    background-image: url('../../assets/landingpage/factmble.png');
    height: 1000px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    /* margin-top: 100px; */
  }

  .wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact1{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  
  }
  .contact123{
   justify-content: flex-start;
   align-items: center;
  }

 .automateCont{
    display: flex;
    justify-content: space-between !important;
   flex-direction: column-reverse !important;
    align-items: center;
    margin-bottom: 1rem;
    /* gap: 20px; */
    
  }
  /* New Adjustment */
  .mainFooter {
    display: flex;
    flex-direction: column !important;
    padding: 150px 10px 20px 10px;

  }
  .imageee{
 display: none;
    }

  .footerConts .name{
    margin-left: 2%;
  }

  .footerConts p{
    display: block;
    color: var(--white) !important;
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'inter';
    padding: 0;
    gap: 5px;
    margin-left: 2%;
  }
  .photoItem img {
    width: 100%;
    height: 200px;
    display: block;
  }

  /* .mainFooter{
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    padding: 12rem  0 30px 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    flex-direction: column;
  } */

  .footerContsnav{
    padding-left: 0rem;
  }
  .sectionEleven{
    padding: 25px;
  }
  .contact12{
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    gap: 20px;
    width: 100%;
  }

  .sectionEleven .ogunMap{
    background-image: url('../../assets/ogunmap2.svg');
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 295.46px;
    overflow: hidden;
    margin-bottom: -8.8rem;
    background-repeat: no-repeat;
    z-index: 1;
      }
      .videoContainer {
        display: grid;
        grid-template-columns: 1fr;
      
        margin-top: 2rem;
        /* padding: 50px 50px 50px 100px; */
        justify-content: center;
        align-items: center;
      }

  .sectionEleven .ogunMap h2{
    font-size: 20px;
    font-weight: 700;
    margin-top: 8rem;
    padding-left: 70px;
    margin-right: 1rem;
  }
  .mobileDisplay {
    display: block; 
  }

  .newsGroup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
   
  }

  .mobileImageContainer{
    padding: 20px;
  }
  .mobileImageContainer img{
    width: 100%;
    height: 300px;
    object-fit: block;
  }
  .mobileImageContainer h1{
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }
  .mobileImageContainer p{
    font-size: 14px;
    color: #fff;
   text-align: center;
  }
.mobileTextContainer{
  width: 100%;
  padding: 20px;
  text-align: justify;
}

  .sectionSix{
    background-image: url('../../assets/landingpage/sectorsmble.png');
    height: 2422px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  

  .sectionSeven{
    background-image: url('../../assets/landingpage/banng.png');
    /* background-size: contain; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    width: 100%;
    height: 332px;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10rem; */
  
  }

  .sectionSeven .container {
margin-top: -175px;
  }
  .aboutHeader{
    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  
  width: 100%;
    /* margin-bottom: 5rem; */
}
  .viewgallery {
    display: none;
  }

    .viewgallerymble {
      display: block;
      max-width: 100%;
      margin-top: 5rem;
      /* position: relative; */
    }
    .contactContainer{
      margin-top: -2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      /* justify-content: space-between; */
      /* align-items: center; */
      /* padding-left: 5%;
      padding-top: 10%;
      padding-right: 10%; */
      padding-bottom: 20px;
      /* width: 100%; */
    }

  .viewgallerymbleImgBox{
    width: 100%;
    height: 737px;
    overflow: hidden;
  }
  .viewgallerymbleImgBox img{
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
    object-position: center;
  }
  .picturemble {
    width: 430px;
    height: 737px;
  }
  .contactSection{
    /* justify-content: center; */
    background-color: #164B2E;
    width: 100%;
    margin-bottom: 2rem;
    padding-bottom: 5rem;
}

  .sectionEightFlex{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    flex-direction: column;
    /* margin-top: 9rem; */
    /* margin-bottom: 15rem; */
    margin-left: 2rem;
  }

  .sectionEightFlex h6{
    font-size: 18px;
    color: #343A40;
    font-weight: 700;
  }

  .sectionEightFlex h2{
    color: var(--green);
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .sectionText{
    margin-top: 2rem;
    text-align: center;
    font-size: 10px;
    margin-bottom: -9rem;
  }

  .sectionButton{
    margin-top: 10px;
  }


  .automateImg{
    /* width: 100%; */
    max-width: 357.35px;
    overflow: hidden;
    height: 327px;
  }

  .container .automateCont h1{
    font-size: 20px;
    font-weight: 700;
    color: var(--h-color);
    /* padding-right: 5rem; */
  }
  
  .container .automateCont p {
    font-size: 10px;
    font-weight: 300;
    color: #6C757D;
    /* margin-top: -30px; */
    /* white-space: nowrap; */
  }

  .automateCont .cont {
/* margin-left: 1rem; */
width: 85%;
  }

  .thickgreen{
    width: 85px;
    height: 6px;
    background-color: #2D995F;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  .footerText {
    font-size: 10px;
  }
  .videoItem iframe {
    width:350px;
    height: 250px; 
    border: 3px solid #164B2E;
    margin-right: 40px;
    /* margin-bottom: 20px; */
  }

  .threeCards{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 2rem;
   
    /* display: none; */
    
  }
  /* .threeCardsbg{
    background-image: url('../../assets/bg22.svg');
    background-size: cover;
    margin-top: 105px;
    padding-bottom: 50px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  } */
  .threeCardsbg{
    background-image: url('../../assets/bg22.svg');
    background-size: cover;
    margin-top: 0px;
    padding-bottom: 0 ;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom:0;
  
  }
  
  .cards{
    border-radius: 10px;
    height: auto;
    width: 100%;
    padding: 32px 27px 15px 27px;
    /* display: flex; */
    /* flex-direction: column; */
    /* margin-right: 25px; */
  }
  .aboutPicture{
    width: 100%;
    padding-right: 5rem;
   
}
.photoGroup {
  grid-template-columns: 1fr; /* 1 image per row on mobile */
  width: 100%; /* Adjust width to fit the mobile screen */
  margin-left: 0; /* Remove left margin on mobile */
}
  .cardText{
    margin-top: 10px;
  }
  .investBanner{
    text-align: center;
    font-size: 76px;
    color: white;
    font-weight: 900;
    margin-top: 50px;
    margin-bottom: -12px;
    /* padding: 64px 28px 198px 28px; */
  }
  .investBanner1{
    text-align: center;
    font-size: 24px;
    color: white;
    font-weight: 700;
    margin-bottom: -12px;
  
  }

  .webheader {
    display: none !important;
  }

  .mbleheader {
    display: block;
  }

  .footerConts .footerContsnav {
width: 95%;
  }

  .card4:hover {
    transform: none;
    box-shadow: 3px 5px 5px #34B16E17;
    background-color: white;
  }
  
  .card4:hover .cardItems h2,
  .card4:hover .cardItems .groupedCont a {
    color: green;
  }
  
  

}


/* header nav CSS */
*,
::after,
::before{
    padding: 0;
    margin: 0;
}
/* colors */
:root{
    --white:  #ffffff;
    --light-blue:#DDF3E7;
    --green:#2D995F;
    --text:#6C757D;
    --h-tags:#343A40;
    --Light-Black: #6C757D;
}
button{
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 700;
    border-radius: 4px;
}
.greenBtn{
    background-color: var(--green);
    color: white;
    transition: ease 0.3s;
    border: 1px solid var(--green);
    padding: 12px 20px;
    border-radius: 4px;
}

/* .navContainer .logoCont{
    width: 300px;
    height: 50px;
    overflow: hidden;
}
.logoicon{
    width: 100%;
    height: 100%;
    object-fit: cover;
} */
.greenBtn:hover{
    background-color: #87c5a4;
    border: none;
}
.whiteBtn{
    border: 1px solid var(--green);
    color: var(--green);
    padding: 16px 40px;
    margin-right: 20px;
    border-radius: 4px;
}
.whiteBtn:hover{
    border: 1px solid #87c5a4;
    color: #87c5a4;
}

.headerSection{
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 3px 3px 5px rgba(138, 137, 137, 0.226);
    /* padding: 15px 0; */
    position: fixed;
    background-color: var(--white);
    z-index: 9999;
    height: 65px;
}
.nav{
    background-color: var(--white);
    width: 100%;
    max-width: 90%;
}
.linkList li{
  font-weight: 500 !important;
  color: #6C757D !important;
}
.linkList li:hover{
  color: #2D995F !important;
}
.navContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    position: relative;
    z-index: 9999;
    bottom: 20px;
    color: #000;
    /* height: 50px; */
}
.mdaButton{
    height: 48px;
    width: 228px;
    background-color: #2D995F;
    color: white;
    text-align: center;
}
.mdaButton:hover{
    background-color: #15492d;
    color: white;
    text-align: center;
}
.navContainer .navLogoMobile{
    display: none;
}
.navContainer .navLogoWeb,
.navContainer .navLogoMobile{
    /* height: 70px; */
    max-width: 143px;
    overflow: hidden;
}

.navContainer .navLogoWeb img,
.navContainer .navLogoMobile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
ul{
    /* display: non; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: 100%;
    max-width: 74%;
    transition: ease top 0.5s;
    margin: 0;
}
ul li{
    display: flex;
}
ul li:nth-child(1) a{
    color: black ;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 500;
}
ul li:nth-child(1) a:hover{
    color: var(--green);
    font-weight: 700;
}
.activeLink{
    color: #2D995F;
}
ul a{
    text-decoration: none;
    color: black; 
}


.harmborgers{
    display: none;
    font-size: 24px;
}
.linkList{
    color: #000 !important;
}

@media(max-width:800px){
    .navContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 9999;
        bottom: 20px;
        color: #000;
        width: 100%;
        padding: 20px;
    }

    ul li:nth-child(1) a {
        color: black !important;
    }
    /* Nav  */
    .headerSection{
        padding: 20px  0;
        z-index: 999;
        height: 80px;
        /* margin-left: 5px; */
    }
    .navContainer .navLogoWeb{
        /* display: none; */
    }
    .photoContainer{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      }
    .bioContainer{
display: none;
  }

    .navContainer .navLogoWeb,
    .navContainer .navLogoMobile{
    /* height: 70px; */
    max-width: 128px;
    overflow: hidden;
}
 .drpdwnitem .legenddrpdwn2 {
    color: black;
    
}
.bioUpdate{
  background-color: #22724747;
  /* max-height: 700px;
   overflow-y: scroll; */
  /* padding: 50px 30px 30px 30px; */
  width: 100%;
}
.bioUpdate p{
  order: 2;
  color: white;
  font-size: 14px;
}
    .navContainer .navLogoMobile{
        display: block;
        width: 206px;
        margin-left: 20px;
    }
    .navContainer .navLogoMobile img{
        object-fit: contain;
    }
   
    .contImg{
      justify-content: flex-start;
      object-fit: block;
       height: 200px;
       width: 200px;
       margin-bottom: 30px;
      }
    /* Nav Menus */
    .nav{
        max-width: 100%;
        /* padding: 0 27px; */
    }
    ul{
        position: absolute;
        display: block;
        top: 90px;
        /* left: 0; */
        width: 100%;
        max-width: 89%;
        padding: 30px 27px ;
        justify-content: left;
        background-color: var(--white);
        margin: 0;
        gap: 10px; 
    }
    .photoLine{
      display: none;
      }
    .mainMenu {
        margin: 0;
      }
    .menuOpen{
        display: none;    
    }
    ul .linkList{
        display: flex;
        flex-direction: column;
    }
    ul li{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 25px;
        padding:0;
    }
    ul li:nth-child(1) a{
        padding: 0;
        color: black;
    }
    button{
        font-weight: 400;
        color: black;
    }
    .greenBtn{
        background-color: transparent;
        /* color: var(--Light-Black); */
        border:none;
        padding:0;
        /* border-radius: 4px; */
    }
    /* .greenBtn:hover{
        background-color: #87c5a4;
        border: none;
    } */
    .whiteBtn{
        border: none;
        /* color: var(--Light-Black); */
        padding: 0;
        margin-right: 0;
        margin-top: 25px;
        /* border-radius: 4px; */
    }
    .firstBox{
      width: 100%;
      background-color: #F5F5F5;
      padding-left: 1rem;
      padding-bottom: 2rem;
      /* height: 300px; */
      padding-top: 5rem;
      }
      .secondBox{
      width: 100%;
      background-color: #2D995F;
      padding-left: 1rem;
      padding-bottom: 2rem;
      /* height: 300px; */
      padding-top: 5rem;
      }
    .firstBox h1{
      font-size: 50px;
      font-weight: 900;
      color:  #2D995F;
      padding-right: 40px;

  }
  .firstBox p{
      font-size: 18px;
      font-weight: 400;
      color:  #6C757D;
      padding-right: 40px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
  }
  
  .secondBox p{
      font-size: 18px;
      font-weight: 400;
      color:  #fff;
      padding-right: 40px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
  }
  .secondBox h1{
      font-size: 50px;
      font-weight: 900;
      color:  white;
  }
    /* .whiteBtn:hover{
        border: 1px solid #87c5a4;
        color: #87c5a4;
    } */
    
    /* ul li:nth-child(1) a:hover{
        color: var(--green);
        font-weight: 700;
    } */
    /* ul a{
        text-decoration: none;
        color: var(--Light-Black); 
    } */

    /* Nav Menu Buttons */
    .harmborgers{
        display: block;
        margin-right: 27px;
    }
    /* .harmborgers .closs{
        display: none;
    } */
    .harmborgers span i{
        font-size: 24px;
    }
}

/* New footer */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

:root{
  --p-color:#6C757D; 
  --h-color:#343A40;
  --green:#2D995F;
  --white: #ffffff;
}
body {
  overflow-x: hidden;
  font-family: inter;
}



p{
  color: var(--white);
}
h1{
  font-size: 64px;
}
.wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.FooterBg{
  background-color: #343A40;
  z-index: 1;
}
.FooterBg2{
  background-color: #343A40;
  z-index: 1;
}

.container{
  width: 100%;
  max-width: 1220px;
}
.containers{
  width: 100%;
  position: relative;
}
.containers .halfmap{
  /* z-index: 1; */
  width: 437px;
  /* height: 382px; */
  position: absolute;
  left: 35%;
  /* top: 0; */
  bottom: 380px;
}
.mainFooter{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 14rem  10px 30px 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);

}
.footerLogo{
  margin-bottom: 15px;
  cursor: pointer;
}
.footerLogoCont p{
  margin-left: 10px;
  font-weight: 400;
  /* font-size: 20px; */
}
.footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
}
.footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
}
.footerConts p{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  /* font-size: 18px; */
  font-weight: 400;
  font-family: 'inter';
  padding: 0;
  gap: 5px;
}

/* .footerLogoCont p{
  margin-left: 10px;
} */
/* .footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
} */
/* .footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
} */
.footerContsnav{
  padding-left: 4rem;
  
}
.footerContsnav a{
  text-decoration:none;

}

.footerInput{
  background-color: #F2F5F3;
  /* border: none; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-top: 10px;
}
.footerInput input{
  background-color: transparent;
  /* padding: 10px 5px; */
  border: none;
  outline: none;
  font-size: 14px;
}
.footerInput img{
  cursor: pointer;
}
.sectionEleven{
  width: 100%;
  position: relative;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  background-color: #ACE7C71A;
}
.sectionEleven .ogunMap{
  background-image: url('../../assets/ogunmap2.svg');
  background-size: contain;
  background-position:center;
  width: 100%;
  height: 450px;
  overflow: hidden;
  margin-bottom: -9.9rem;
  background-repeat: no-repeat;
  /* margin-top: 3rem; */
  /* text-align: center; */
  /* z-index: 999; */
  margin-top: -1rem;
}
  .sectionEleven .ogunMap h2{
    font-size: 53px;
    font-weight: 900;
    margin-top: 15rem;
    padding-left: 30px;
    text-align: center;
    /* margin-right: 1rem; */
  }
  .footerInput{
    background-color: #F2F5F3;
    /* border: none; */
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    margin-top: 10px;
  }
  .footerInput input{
    background-color: transparent;
    /* padding: 10px 5px; */
    border: none;
    outline: none;
    font-size: 14px;
  }
  .footerInput img{
    cursor: pointer;
  }
  .footerText{
    background-color: #000;
    text-align: center;
   height: 100%;
   display: flex;
   flex-direction: column;
   /* border-top: 2px solid white; */
   padding-top: 20px;
  
  }
  .linksClass{
    color: white;
  }
  .subFooter{
    color: white;
  }
  .subFooter1{
    color: white;
    margin-top: -10px;
  }
  .footList li{
color: white;
text-decoration: none !important;
text-transform: none !important;
padding: 0 !important;

  }
  .footList li:hover{
color: #2D995F;
  }
  .videoWidth{
    width: 900px;
    height: 600px
  }

/* :placeholder-shown{
  font-size: x-small;
  padding-left: 20px;
} */

@media(max-width:800px){
  .wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containers .halfmap{
    /* z-index: 1; */
    width: 238px;
    height: 195px;
    position: absolute;
    left: 20%;
    top: -5.5%;
    /* z-index: 999; */
  }
  /* New Adjustment */
  .mainFooter {
    display: flex;
    flex-direction: column !important;
    padding: 150px 10px 20px 10px;

  }
  .aboutText h1{
    text-align: center;
    color: #000000;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 70px;
    }

  .footerConts .name{
    margin-left: 2%;
  }

  .footerConts p{
    display: block;
    color: var(--white) !important;
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'inter';
    padding: 0;
    gap: 5px;
    margin-left: 2%;
  }
  .aboutSection{
    margin-top: 2rem; 
   
  }

  .footerContsnav{
    padding-left: 0rem;
  }
  .sectionEleven{
    padding: 25px;
  }

  .sectionEleven .ogunMap{
    background-image: url('../../assets/ogunmap2.svg');
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 390.46px;
    overflow: hidden;
    margin-bottom: -11.6rem;
    background-repeat: no-repeat;
    z-index: 1;
    /* padding-top: 4rem; */
      }

  .sectionEleven .ogunMap h2{
    font-size: 20px;
    font-weight: 700;
    margin-top: 13rem;
    padding-left: 20px;
    margin-right: 1rem;
    text-align: center;
  }

  .footerText {
    font-size: 10px;
  }

  .threeCards{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    /* padding: 0 100px; */
    /* margin-top: -50px; */
  }

  .footerConts .footerContsnav {
  width: 95%;
  }
  .wrapperBackground h1{
    margin-top: 6rem;
    text-align: center;
    color: #343A40;
    font-weight: 900;
    font-size: 30px;
    padding-left: 10%;
    padding-right: 10%;
}
.videoSection{
  display: flex;
  justify-content: center;

}

.videoWidth{
  width: 900px;
  height: 200px
}
.aboutPicText{
  margin-top: -4rem;
  padding-left: 20px;
  font-size: 16px;
  width: 100%;
}
}

