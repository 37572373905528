.entirity {
   width: 100%; 
   align-items: center;
}

.navcntnt {
    display: flex;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 1rem;
    width: 100%;
    background-color: white;
    /* border: 1px solid red; */
}

.Logo {
    width: 331.49px;
    height: 72.61px;
}

.flexcontainer {
    display: flex; /* Use flexbox layout */
    justify-content: space-around; /* Distribute items evenly along the main axis */
    align-items: center; /* Center items along the cross axis */
    margin-left: 150px;
    margin-right: 150px;
}

ul {
    list-style-type: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
    display: flex; /* Use flexbox layout */
    justify-content: space-around;
    
}

li {
    padding: 20px; /* Add padding around list items */
    cursor: pointer; /* Add pointer cursor on hover (optional) */
    font-size: 15px;
    color: #6C757D;
}

li:hover {
    color: #2D995F;
}

.mdas {
    font-family: 'inter';
    font-weight: 700;
    font-size: 24px;
}