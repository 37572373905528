.herobanner {
    width: 100%;
    height: 655.02px;
}

.herocontainer {
    position: relative;
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.visioncard {
    width: 642.97px ;
   height: 356.12px;
   background-color: #F5F5F5;
}

.visiontxt {
    color: #2D995F;
    font-size: 48.19px;
    font-weight: 900;
    padding-top: 99.34px;
    padding-left: 63.24px;
    margin-bottom: 1px;
    font-family: 'inter';
}

.visiontxt2 {
    color: #6C757D;
    font-weight: 400;
    font-size: 13.55px;
    padding-left: 63.24px;
    width: 80%;
    font-family: 'inter';
}
.missioncard {
    width: 642.97px ;
   height: 356.12px;
   background-color: #2D995F;
}

.missiontxt {
    color: white;
    font-size: 48.19px;
    font-weight: 900;
    padding-top: 99.34px;
    padding-left: 63.24px;
    margin-bottom: 1px;
    font-family: 'inter';
}

.missiontxt2 {
    color: white;
    font-weight: 400;
    font-size: 13.55px;
    padding-left: 63.24px;
    width: 80%;
    font-family: 'inter';
}

.allkads {
   margin-top: -193px; 
   display: flex;
}

.dabout {
    display: flex;
    margin-left: 5rem;
}

.hrline {
    width: 498.98px;
    height: 2.8px;
}

.abouttext {
    font-size: 167.72px;
    font-weight: 900;
    font-family: 'inter';
    margin-top: 50px;
    width: 55%;
}

.mnstry0furban {
    width: 50%;
    margin-top: 45px;
    
}

.murpptxt {
    color: #343A40;
    font-family: 'inter';
    font-size: 19.57px;
    font-weight: 700;
    width: 50%;
    margin-bottom: 0px;
}

.lorem {
    width: 85%;
    color: #6C757D;
}

.aerialview {
    width: 100%;
    height: 741.6px;
    margin-top: -81px;
}

.ourgoals {
    display: flex;
    justify-content: space-around;
    margin-top: 92px;
}

.kad1 {
    height: 319.34px;
    width: 301.81px;
}
.kad2 {
    height: 319.34px;
    width: 301.81px;
}
.kad3 {
    height: 319.34px;
    width: 301.81px;
}

.goalstxt {
    font-size: 36.58px;
    font-family: 'inter';
    font-weight: 800;
    width: 5%;
    padding-top: 90px;
}
.wholekad {
    display: flex; /* Use flexbox layout */
    justify-content: space-between; /* Distribute items evenly along the main axis */
    align-items: center; /* Center items along the cross axis */
    width: 75%;
}

.awrdrcgntn {
    width: 730.67px;
    height: 519.89px;
    
}

.imgawrdrcgntn {
    margin-top: 108px;
    display: flex;
}

.awrdsrcgtntxt {
    font-size: 49.88px;
    font-weight: 900;
    color: #343A40;
    line-height: 55px;
    margin-bottom: 15px;
}

.awrdrcgntnhr {
    width: 197.98px;
    height: 21.82px;
}

.viewawrds {
    display: flex;
    align-items: center;
    color: #2D995F;
}
.awrdrcgntnarrow {
    width: 18.71px;
    height: 18.71px;
    margin-left: 7.79px;
}

.textpart {
    margin-left: 75px;
    margin-top: 113px;
    font-family: 'inter';
}

.ourprogrm {
    display: flex;
    justify-content: space-around;
}

.ourprgrmtxt {
    font-size: 13.69px;
    color: #6C757D;
    font-weight: 400;
}

.ourprgrmhr {
    width: 860.59px;
    height: 3.04px;
    margin-top: -15px;
}

.prgrmtxt {
    font-size: 48.67px;
    font-weight: 900;
    line-height: 55px;
}

.txt2 {
    width: 92%;
    margin-top: 10px;
}
.txt1 {
    margin-bottom: 7px;
}

.ourprgrmtxt {
    width: 70%;
    margin-top: 35px;
}

.servicekad {
    width: 1177px;
    height: 662.25px;
    display: flex;
    justify-content: center;
    margin-left: 45px;
}

.leftrightcnt {
    display: flex;
    justify-content: center;
    margin-top: 105px;
}

.murpleft {
    width: 580.97px;
    height: 406.38px;
}
.murpright {
    width: 596.03px;
    height: 406.38px;
}

.ourpartners1 {
    width: 169px;
    height: 145.95px;
}


.patnatxt {
    font-size: 46.99px;
    font-weight: 900;
    line-height: 55px;
}

.ourpatnas {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
    margin-bottom: 63px;
    margin-top: 63px;
}

.thepatnas {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 90px;
    font-family: 'inter';
}