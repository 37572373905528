@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

:root{
  --p-color:#6C757D; 
  --h-color:#343A40;
  --green:#2D995F;
  --white: #ffffff;
}
body {
  overflow-x: hidden;
  font-family: inter;
}



p{
  color: var(--white);
}
h1{
  font-size: 64px;
}
.wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.FooterBg{
  background-color: #343A40;
  z-index: 1;
}
.FooterBg2{
  background-color: #343A40;
  z-index: 1;
}

.container{
  width: 100%;
  max-width: 1220px;
}
.containers{
  width: 100%;
  position: relative;
}
.containers .halfmap{
  /* z-index: 1; */
  width: 437px;
  /* height: 382px; */
  position: absolute;
  left: 35%;
  /* top: 0; */
  bottom: 347px;
}
.mainFooter{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 14rem  10px 30px 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);

}
.footerLogo{
  margin-bottom: 15px;
  cursor: pointer;
}
.footerLogoCont p{
  margin-left: 10px;
  font-weight: 400;
  /* font-size: 20px; */
}
.footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
}
.footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
}
.footerConts p{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  /* font-size: 18px; */
  font-weight: 400;
  font-family: 'inter';
  padding: 0;
  gap: 5px;
}

/* .footerLogoCont p{
  margin-left: 10px;
} */
/* .footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
} */
/* .footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
} */
.footerContsnav{
  padding-left: 4rem;
  
}
.footerContsnav a{
  text-decoration:none;

}

.footerInput{
  background-color: #F2F5F3;
  /* border: none; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-top: 10px;
}
.footerInput input{
  background-color: transparent;
  /* padding: 10px 5px; */
  border: none;
  outline: none;
  font-size: 14px;
}
.footerInput img{
  cursor: pointer;
}
.sectionEleven{
  width: 100%;
  position: relative;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  background-color: #ACE7C71A;
}
.sectionEleven .ogunMap{
  background-image: url('../assets/ogunmap2.svg');
  background-size: contain;
  background-position:center;
  width: 100%;
  height: 450px;
  overflow: hidden;
  margin-bottom: -9.9rem;
  background-repeat: no-repeat;
  /* margin-top: 3rem; */
  /* text-align: center; */
  /* z-index: 999; */
  margin-top: -1rem;
}
  .sectionEleven .ogunMap h2{
    font-size: 53px;
    font-weight: 900;
    margin-top: 15rem;
    padding-left: 30px;
    text-align: center;
    /* margin-right: 1rem; */
  }
  .footerInput{
    background-color: #F2F5F3;
    /* border: none; */
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    margin-top: 10px;
  }
  .footerInput input{
    background-color: transparent;
    /* padding: 10px 5px; */
    border: none;
    outline: none;
    font-size: 14px;
  }
  .footerInput img{
    cursor: pointer;
  }
  .footerText{
    background-color: #000;
    text-align: center;
   height: 100%;
   display: flex;
   flex-direction: column;
   /* border-top: 2px solid white; */
   padding-top: 20px;
  
  }
  .linksClass{
    color: white;
  }
  .subFooter{
    color: white;
  }
  .subFooter1{
    color: white;
    margin-top: -10px;
  }
/* :placeholder-shown{
  font-size: x-small;
  padding-left: 20px;
} */

@media(max-width:800px){
  .wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containers .halfmap{
    /* z-index: 1; */
    width: 238px;
    height: 195px;
    position: absolute;
    left: 20%;
    top: -5.5%;
    /* z-index: 999; */
  }
  /* New Adjustment */
  .mainFooter {
    display: flex;
    flex-direction: column !important;
    padding: 150px 10px 20px 10px;

  }

  .footerConts .name{
    margin-left: 2%;
  }

  .footerConts p{
    display: block;
    color: var(--white) !important;
    line-height: 28px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'inter';
    padding: 0;
    gap: 5px;
    margin-left: 2%;
  }

  .footerContsnav{
    padding-left: 0rem;
  }
  .sectionEleven{
    padding: 25px;
  }

  .sectionEleven .ogunMap{
    background-image: url('../assets/ogunmap2.svg');
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 390.46px;
    overflow: hidden;
    margin-bottom: -11.6rem;
    background-repeat: no-repeat;
    z-index: 1;
    /* padding-top: 4rem; */
      }

  .sectionEleven .ogunMap h2{
    font-size: 20px;
    font-weight: 700;
    margin-top: 13rem;
    padding-left: 20px;
    margin-right: 1rem;
    text-align: center;
  }

  .footerText {
    font-size: 10px;
  }

  .threeCards{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 0 100px;
    margin-top: -50px;
  }

  .footerConts .footerContsnav {
  width: 95%;
  }
}