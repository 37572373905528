@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

:root{
  --p-color:#6C757D; 
  --h-color:#343A40;
  --green:#2D995F;
  --white: #ffffff;
}
body {
  overflow-x: hidden;
  font-family: inter;
}

.photoSection img {
  width: 400px;
  height: 300px;
  display: block;
}

p{
  color: var(--p-color);
}
h1{
  font-size: 64px;
}
 h2, h3, h4, h5, h6{
  /* color: var(--h-color); */
}
/* button{ */
.btnss{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 31px;
  font-weight: 700;
  cursor: pointer;
}
.generalPadding{
  padding-top: 103px;
}
.wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero{
  width: 100%;
  /* background-image: url('../../assets/carousel\ 4.svg'); */
  height: 680px;
  /* background-size: contain; */
  color: var(--white);
  background-repeat: no-repeat;
  display: flex;
  /* justify-content: center; */
  background-position: center;
  animation: changeBackground 40s infinite;
  /* align-items:center; */
}
.endded {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
}

.newsHeader{
  /* text-align: center; */
}

@keyframes changeBackground {
  0% {
    background-image: url('../../assets/carousel\ 4.svg');
  }
  25% {
    background-image: url('../../assets/carousel2.svg');
  }
  50% {
    background-image: url('../../assets/carousel3.svg');
  }
  75% {
    background-image: url('../../assets/carousel4.svg');
  }
  100% {
    background-image: url('../../assets/carousel\ 4.svg');
  }
}

.heroCont{
  max-width: 1191px;
  margin-top: 15rem;
  margin-left: 4rem;
  /* text-align: center; */
  height: 200px;
}
.heroCont h1{
  font-weight: 900;
  line-height: 4rem;
  /* max-width: 467px; */
  /* padding: 0 300px; */
}
.heroCont p{
  font-size: 20px;
  margin: 11px 0 20px 0;
  color: var(--white);
}
.heroBtns{
  color: var(--green);
  font-weight: 700;
  font-size: 18px;
  margin-top: 40px;
}
.heroBtns:hover{
  color: #05552a;
}
.threeCardsbg{
  background-image: url('../../assets/bg22.svg');
  background-size: cover;
  margin-top: 80px;
  padding-bottom: 50px;
  width: "100%";
  height: 292px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;

}
.threeCards{
  display: flex;
  justify-content: space-around;
  gap: 30px;
  align-items: center;
  padding: 0 100px;
}
.threeCards h2{
  color: var(--green);
  font-size: 26px;
  font-weight: 700;
}
.sectionThree{
  background-image: url('../../assets/landingpage/fact.png');
  height: 600px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.sectionFour{
  background-image: url('../../assets/landingpage/iseya.png');
  height: 658px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.greenBtn{
  background-color: var(--green);
  color: var(--white);
  /* font-weight: 700; */
}
.greenBtn:hover,
.pricingBtns:hover{
  background-color: #709b84;
}
.heroGreenBtn{
  padding: 16px 63px;
}
.heroAmounts{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 146px;
  margin: 25px 0 0 45px ;
}
.heroAmounts h1{
  font-size: 64px;
  font-weight: 300;
}
.container{
  width: 100%;
  max-width: 1220px;
}
.container .automateCont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
}
.bgcol{
  background-color: #F7FDF9;
}
.container .automateCont .cont{
  max-width: 550px;
}
.thickgreen{
  width: 116px;
  height: 17px;
  background-color: #2D995F;
  margin-bottom: 32px;
}

.container .automateCont h1{
  font-size: 56px;
  font-weight: 800;
  color: var(--h-color);
  /* padding-right: 5rem; */
}

.container .automateCont p{
  font-size: 16px;
  font-weight: 400;
  color: #6C757D;
  /* margin-top: -30px; */
  /* white-space: nowrap; */
}

.sectionSix{
  background-image: url('../../assets/landingpage/sectors.png');
  height: 920px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.sectionEight {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.2s, transform 2s;
}

.sectionEight.active {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.text-animation {
  animation: slideInFromRight 5s ease-out forwards; /* Increase duration to 1s */
  animation-delay: 0.5s;
}

.sectionSeven{
  background-image: url('../../assets/midbanner.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 477px;
  justify-content: center;
  align-items: center;

}

.sectionText{
  margin-top: 18rem;
  text-align: center;
}
.sectionButton{
  margin-bottom: 10px;
}
.sectionEight{
  background-image: url('../../assets/SectionEightBg.svg');
  height: 840px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.sectionEightFlex{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: center; */
  justify-content: space-between;
}
.sectionEightFlex h6{
  font-size: 28px;
  color: #343A40;
  font-weight: 700;
}
.sectionEightFlex .div{
  max-width: 528px;
}
.sectionEightFlex .div small{
  font-size: 20px;
  color: #2D995F;
}
.sectionEightFlex .div p{
  font-size: 36px;
  font-weight: 700;
  /* color: #2D995F; */
}
.sectionEightFlex h2{
  color: var(--green);
  font-size: 44px;
  font-weight: 900;
}

.sectionNine{
  background-image: url('../../assets/sectionNineBg.svg');
  height: 1040px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.sectionNineCont{
  color: var(--white);
  max-width: 623px;
  text-align: center;
  margin-top: 58px;
}
.sectionNineCont h4{
  font-weight: 700;
  font-size: 24px;
}
.sectionNineCont h2{
  font-weight: 900;
  font-size: 54px;
  line-height: 65px;
}
.sectionTen{
  background-image: url('../../assets/sectionTentBg.svg');
  height: 800px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 92px 0;
  gap: 35rem;
  /* align-items: start; */
}
.sectionTen h3{
  color: #343A40;
  font-size: 36px;
  font-weight: 600;
}
.sectionTen button{
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  padding: 16px 64px;
}
.sectionEleven{
  width: 100%;
  position: relative;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
.sectionEleven .ogunMap{
  background-image: url('../../assets/ogunmap2.svg');
  background-size: contain;
  background-position: center;
  width: 557px;
  height: 402px;
  overflow: hidden;
  margin-bottom: -8.8rem;
  background-repeat: no-repeat;
  z-index: 999;
}
.sectionEleven .ogunMap h2{
  font-size: 53px;
  font-weight: 900;
  margin-top: 12rem;
  padding-left: 30px;
  /* margin-right: 1rem; */
}
.dwnldbtn2{
  /* width: 30%; */
  /* padding: 5px; */
  width: 200px;
  height: 35px;
  /* height: 39px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  color: #2D995F;
  border: 1px solid #2D995F;
  text-decoration: none;
  text-transform: none;
  border-radius: 4px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dwnldbtn2:hover{
  background-color: #2D995F;
  color: white;
}

.newsSection h3{
  color: #343A40;
  font-size: 36px;
  font-weight: 600;
}
.newsSection1 h3{
  color: #343A40;
  font-size: 36px;
  font-weight: 600;
}
.newsSection button{
  color: var(--white);
  font-weight: 700;
  font-size: 18px;
  padding: 16px 64px;

}

.newsGroup{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.newsContainer{
  background-color: #fff;
  width: 300px;
  height: 500px;
  box-shadow: 0px 4px 20px 0px rgba(220, 220, 220, 0.25);
  border-radius: 8px;
  
}

.actionP{
  font-size: 16px;
   color: "#2D995F";
   cursor: pointer;
}
.actionP :hover{
  color: #0a4b28;
}

.automateImg{
  /* width: 100%; */
  max-width: 816px;
  overflow: hidden;
  height: 738px;
}
.imgConts{
  width: 100%;
  overflow: hidden;
  height: 300px;
}
.imgs{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px 15px 0 0;
}




.cards{
  border-radius: 10px;
  padding: 43px 41px;
}

.cardItems h5{
  font-size: 18px;
  font-weight: 700;
  margin: 63px 0 22px 0;
}
.cardItems p{
  font-size: 12px;
  font-weight: 400;
}
.eclipse {
  width: 61px;
  height: 61px;
}
.womanimg {
  width: 400px;
  height: 245px;
  margin-top: 10rem;
}
.card1{
  background-color: rgba(255, 186, 8, 0.3);
}
.card2{
  background-color: rgba(172, 231, 199, 0.3);
}
.card3{
  background-color: rgba(253, 101, 132, 0.2);
}
.card4{
  background-color: var(--white);
  box-shadow: 7px 7px 7px #34B16E17;
  transition: ease 0.5s;
}

.card4:hover{
  transform: scale(1.1);
  box-shadow: 3px 5px 5px #34B16E17;
}
.card5{
  background-color: #bdcde6;
}
.card6{
  background-color: rgba(172, 231, 199, 0.3);
}


.efficientCard {
  width: 33.3333333%;
  background-color: #F9F9F9;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:137px 28px 100px 77px; */
}

.efficientCardGreen {
  width: 33.3333333%;
  background-color: #2D995F;
  height: 300px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding:137px 28px 100px 77px; */
}
.efficientCardGreen .efficientxt{
  color: var(--white) !important;
}
.effiCont{
  max-width: 466px;
}
.efficientxt {
  font-size: 26px;
  font-weight: 700;
  padding-bottom: 18px;
}

.uder {
  font-size: 14px;
  color: #6C757D;
  font-weight: 400;
}

.howItWorks {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 81px;
  /* padding-left: 50px; */
  /* padding-top: 81px; */
}
.howItWorksCont{
  max-width: 527px;
  /* padding-right: 9rem; */
}
.howItWorksTxt {
  font-size: 72px;
  font-weight: 800;
  color: #343A40;
}

.underhowItWorksTxt {
  color: #6C757D;
  font-size: 19px;
  padding: 24px 0 62px 0;
  /* margin-top: -80px; */
  /* font-family: inter; */
  font-weight: 400;
}

.options {
  display: flex;
}

.optsdeg {
  width: 315px;
  height: 239px;
  background-color: #343A40;

}

.optsdeg2 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  margin-left: 43px;

}

.optsdeg23 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  margin-left: 43px;
  margin-top: 32px;

}

.optsdeg3 {
  width: 315px;
  height: 239px;
  background-color: #F5F5F5;
  /* margin-left: 43px; */
  margin-top: 32px;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  color: #343A40;
  font-size: 36px;
  /* text-align: center; */
  margin-left: 24px;
  margin-top: 28px;
  /* line-height: 60px; */
  font-weight: 700;
}

.si {
  font-weight: 700;
  font-size: 25px;
  color: #E9ECEF;
  margin-left: 35px;
  margin-top: 26px;
}

.si2 {
  font-weight: 700;
  font-size: 26px;
  color: #6C757D;
  margin-left: 35px;
  margin-top: 26px;
}

.underSi {
  font-size: 14px;
  font-weight: 400;
  color: #E9ECEF;
  margin-left: 36px;
  /* margin-top: -30px; */
}

.underSi2 {
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
  margin-left: 36px;
  /* margin-top: -30px; */
}

.mobile {
  /* margin-top: 152px; */
  /* margin-left: 81px; */
  width: 602px;
  height: 1221px;
  overflow: hidden;
}


.bgColor{
  background-color: rgba(172, 231, 199, 0.1);
  /* padding-bottom: 3rem; */
}
.pricingSection{
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.pricingSectionCont{
  text-align: center;
}
.pricingTxt {
  font-size: 36px;
  font-weight: 700;
  /* text-align: center; */
  /* margin-left: 50%; */
  padding-top: 83px;
}

.under_pricingTxt {
  font-size: 18px;
  font-weight: 400;
  color: #6C757D;
}

/* .pricingOpts {
  margin-top: 76px;
} */

.containerForPricing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-bottom: 3rem;

}

.pricingDesg {
  background-color: var(--white);
  position: relative;
  padding: 32px 34px;
  height: 620px;
  border-radius: 20px;
  /* padding-bottom: 20px; */
  box-shadow:2px 2px 5px rgba(5, 5, 5, 0.15);
}

.pricingDesg2 {
  background-color: #2D995F;
  /* background-color: pink; */
  /* width: 332px;
  height: 576px;
  border-radius: 20px;
  padding-bottom: 20px;
  box-shadow: rgba(172, 231, 199, 0.15); */
}

.pricingPad h4 {
  font-size: 24px;
  /* color: #343A40; */
  font-weight: 700;
}
.pricingPad2 h4 {
  font-size: 24px;
  color: var(--white);  
  font-weight: 700;
}
.pricingPad2 p{
  color: var(--white) !important;
} 
.pricingDesg2 button{
  background-color: var(--white);
  color: var(--green);
  transition: ease 0.5px;
}
.pricingDesg2 button:hover{
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.freePlan2 {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

/* .pricingPad {
  padding-top: 32px;
  padding-left: 34px;
} */

.amount {
  font-size: 30px;
  font-weight: 700;
  color: #6C757D;
  margin-top: 39px;
}
.amountSpan {
  font-size: 14px;
  font-weight: 400;
}
.useFor {
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
  margin: 15px 0 26px 0;
}
.lists {
  /* padding-left: 5px; */
  color: #6C757D;
  font-size: 14px;
}
.subLists{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  gap: 20px;
  padding: 9px 0;
}
.subLists p{
  font-size: 14px;
  font-weight: 400;
}
.amount2 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 39px;
}


.useFor2 {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: -20px;
}

.tick {
  width: 25px;
  height: 25px;
  /* padding-right: 20px; */
  /* margin-top: 10px; */
}

.tick2 {
  width: 25px;
  height: 25px;
  padding-right: 20px;
  margin-top: 10px;
}

.textUnderUsefor {
  padding-left: 26px;
  color: #6C757D;
  font-size: 14px;
}

.textUnderUsefor2 {
  padding-left: 26px;
  color: #ffffff;
  font-size: 14px;
}

.pricingBtns {
  width: 80%;
  height: 43px;
  color: #fff;
  background-color: #2D995F;
  border-radius: 4px;
  /* margin-left: 17px; */
  text-align: center;
  position: absolute;
  bottom: 20px;
  /* margin-top: 100px; */
  /* line-height: 40px; */
  transition: ease 0.5px;
}

/* .Tryfree2 {
  width: 298px;
  height: 43px;
  color: #2D995F;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 17px;
  text-align: center;
  margin-top: 5px;
  line-height: 40px;
} */

/* .Tryfree3 {
  width: 298px;
  height: 43px;
  color: #fff;
  background-color: #2D995F;
  border-radius: 4px;
  margin-left: 17px;
  text-align: center;
  margin-top: 200px;
  line-height: 40px;
} */


.faqcont{
  width: 100%;
  max-width: 900px;
  padding: 100px 0;
}
.faqsHead{
  text-align: center;
}
.faqsHead h1{
  font-size: 38px;
  font-weight: 800;
}
.faqsHead p{
  font-size: 20px;
  font-weight: 400;
  padding: 16px 0 54px 0;
}

/* .frame1{
  width: 100%;
} */
.footerHero{
  width: 100%;
  /* height: 452px; */
  background-image: url("../../assets/Frame\ 91.png");
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 59px;
  padding-top: 95px;
  padding-bottom: 80px;
}
.footerHeroText{
  font-size: 76px;
  font-weight: 900;
  margin: 0;
  color: var(--white);
}
.btns{
  /* margin-top: 59px; */
  background-color: #ffffff;
  border: none;
  width: 188px;
  height: 44px;
  color: #2D995F;
  border-radius: 4px;
  transition: ease 0.5s;
  font-weight: 700;
}
.btns:hover{
  background-color: transparent;
  color: var(--white);
   border: 1px solid white;
}

.copy{
  line-height: normal;
  margin-top: 60px;
}
.name{
  font-size: 18px;
  font-weight: 700;
  font-family: inter;
  padding-bottom: 20px;
}
.FooterBg{
  background-color: #343A40;
  z-index: 1;
  /* height: 40px; */
}
.mainFooter{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  padding: 8rem  0 30px 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
}
.footerLogo{
  margin-bottom: 15px;
}
.footerLogoCont p{
  margin-left: 10px;
  font-weight: 400;
  /* font-size: 20px; */
}
.footerConts ul{
  padding: 0;
  list-style-type: none;
  display: block !important;
  max-width: 100% !important;
}
.footerConts ul li{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  padding: 0;
}
.footerConts p{
  display: block;
  color: var(--white) !important;
  line-height: 28px;
  /* font-size: 18px; */
  font-weight: 400;
  font-family: 'inter';
  padding: 0;
  gap: 5px;
}
.footerContsnav{
  padding-left: 4rem;
}

.footerInput{
  background-color: #F2F5F3;
  /* border: none; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  margin-top: 10px;
}
.footerInput input{
  background-color: transparent;
  /* padding: 10px 5px; */
  border: none;
  outline: none;
  font-size: 14px;
}
.footerInput img{
  cursor: pointer;
}
.footerText{
  background-color: #000;
  text-align: center;
 height: 100%;
 display: flex;
 flex-direction: column;
 /* border-top: 2px solid white; */
 padding-top: 20px;

}
.subFooter{
  color: white;
}
.subFooter1{
  color: white;
  margin-top: -10px;
}
.topSection{
  margin-top: 7rem;
  /* width: 100%; */
  max-width: 89%;
}
.topSection1{
  margin-top: 3rem;
  width: 100%;
  max-width: 89%;
}
.topSection2{
  margin-top: 7rem;
  width: 100%;
  max-width: 89%;
}
.topSection h1{
  
  /* width: 100%; */
  font-weight: 900;
  font-size: 36px;
  text-align: center;
  color: #343A40;
}
.topSection p{
  text-align: center;
  font-size: 16px;
  color: #6C757D;
  
}
.topSection1 h1{
  border-top: 4px solid #2D995F;;
  border-bottom: 4px solid #2D995F;
  width: 100%;
  font-weight: 900;
  font-size: 60px;
  text-align: center;
}
.topSection2 h1{
  border-top: 4px solid #2D995F;;
  border-bottom: 4px solid #2D995F;
  width: 100%;
  font-weight: 900;
  font-size: 60px;
  text-align: center;
}
.lineHeight{
  height: 8px;
  width: "100%";
  background-color: #2D995F;
  
}
.newImg{
  display: block;
}
.newGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: auto;
  gap: 2em;
}
.newGrid1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: auto;
  gap: 10px;
}
.topSectionLabels{
  align-items: center;
  margin-top: 30px;
  /* border: 1px solid #ADB5BD;
  border-radius: 4px; */
  padding-left: 18px;
}
.topSectionLabel{
  align-items: center;
  margin-top: 30px;
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  padding-left: 18px;
}
.topSectionLabel1{
  /* align-items: center; */
  margin-top: 30px;
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  padding-left: 18px;
}
.newsSection{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.newsSection1{
  display: flex;
  flex-direction: row;
  height: 150px;
  /* justify-content: center; */
  align-items: center;
}
.newsText{
  /* display: flex; */
  flex-direction: column;
}
.newsText1{
  /* display: flex; */
  flex-direction: column;
margin-left: 20px;
}
.newsText p{
  text-align: left;
  -webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 10px;
}
.newsText1 p{
  text-align: left;
  -webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 10px;
}
.newsText h1{
  text-align: left;
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
  -webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 15px;
  padding-top: 30px;
  padding-right: 10px;
}
.newsText1 h1{
  text-align: left;
  color: #343A40;
  font-size: 20px;
  font-weight: 700;
  -webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: 15px;
  padding-top: 30px;
  padding-right: 10px;
}
/* .topSection p{
  -webkit-line-clamp: 2;
display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
} */

.sectionLabel{
  display: flex;
  flex-direction: row;
  /* margin-top: 2rem; */
  align-items: center;
  justify-content: center;
 gap: 50px;
 width: 100%;
}
.postedSection{
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.postedSection p{
text-align: left;
font-size: 14px;
color: #6C757D!important;
}
.comLabel{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 3rem;
  width: 100%;
  margin-top: 4rem;
}

.sectionHead p{
  color: #343A40;
  font-size: 16px;

}

.labelText{
  width: 100%;
  font-size: 20px;
  font-weight: 700;
}
.footerButton{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding-right: 10px;
}
.footerButton p{
  font-size: 10px;

}
.footerButton h6{
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: #2D995F!important;
}
.footerButton h6:hover{
  color: #8be7b6 !important;
}
.belowButton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.labelSection{
  flex-direction: column;
}
.sectionHead{
  flex-direction: column;
}
.chiefSection{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.comHead{
  /* max-width: 23%; */
  transition: ease 0.5s;
  box-shadow: 0.5px 0.5px 10px rgb(175, 174, 174);
  border-radius: 15px;
}
.comHead :hover{
  transform: scale(1.03);
  transition: ease 0.5s;
}

.comHead h5{
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
}
.comHead p{
  text-align: center;
  margin-bottom: 0;
}
.labelSub{
  margin-top: 20px;

}
.accordianBtn{
  width: 100%;
  display: flex;
}
.dated{
  /* margin-top: -50px; */
  font-size: 12px;
  color: #000;
}

@media(max-width:800px){
  .topSection h1{
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 4px;
    padding-top: 10px;
  }
  .newsTitle{
    font-size: 14px !important;
  }
  .date{
    padding-bottom: 5px;
    font-weight: 600  ;
  }
  .mainNews{
    padding-bottom: 20px;
  }
  .topSection p{
    font-size: 10px;  
    margin-bottom: 2px;
  }
  .instruction{
    margin-bottom: 1rem !important;
  }
  .topSectionLabel{
    /* align-items: center; */
    /* border: 1px solid #ADB5BD; */
    /* border-radius: 4px; */
    padding-left:0;
  }

  .newImg{
    display: none;
  }
  .newGrid{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: auto; */
    gap: 1em;
  }
  .newGrid1{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: auto; */
    gap: 1em;
  }
  .footerButton h6{
    font-size: 10px;
    font-weight: 700;
    /* cursor: pointer; */
    /* text-transform: none; */
    /* text-decoration: none; */
    /* color: #2D995F!important; */
  }
  .endded {
    margin-bottom: 2rem;
    /* display: flex; */
    /* justify-content: center; */
    /* margin-top: 25px; */
    /* align-items: center; */
    /* width: 100%; */
  }
  .newsDetails{
    font-size: 14px;
  }
  .postedSection p{
    padding-bottom: 10px;
    }
}