.archvTable{
  padding-top: 5rem;
}
.mainContainter{
  display: flex;
}
.leftContainer{
  /* height: 100vh; */
  /* position: fixed; */
  /* border-right:3px solid green; */
  padding: 50px 24px 60px 50px;
  width: 40%;
}
.filterText img{
  display: none;
}
.leftContainer p{
  color: green;
}

.formGroup {
  width: 100%;
}
.formGroup .label{
  color: black;
  margin-top: 28px;
  /* margin-bottom: 28px; */
}
.rightContainer{
  width: 100%;
  padding: 25px 20px 20px 0;
  /* border: 1px solid green; */ 
}
.archivGco{
  width: 100%;
  border-top: 1px solid #2D995F;
  border-left: 1px solid #2D995F;
  border-right: 1px solid #2D995F;
}
.filt{
  color: #6C757D;
  font-size: 20;
  font-weight: 700;
  padding: 20px 0 0;

  /* margin-top: 5rem; */
}
.endded {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
  width: 100%;
}

.dwnldbtn1{
  margin-top: 28px;
  width: 100%;
  background-color: #2D995F;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.dwnldbtn2{
  /* width: 30%; */
  padding: 14px 60px;
  /* height: 39px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #2D995F;
  border: 1px solid #2D995F;
  text-decoration: none;
  text-transform: none;
  border-radius: 4px;
  font-weight: 500;
}
.archHeader{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #343A40;
  font-weight: 700;
  padding: 20px 0 20px;
}
.archLabel{
  font-size: 20px;
  color: #6C757D;

}
.archContainer{
  width: 100%;
  height: auto;
  border-bottom: 1px solid #2D995F;
  padding: 10px;
  margin-bottom: 28px;
}
.archContainerLast{
  border: none;
}
.archiveFlex{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.webShow{
  display: block;
}
.mobileShow{
  display: none;
}
.directions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5rem;
}

.archSub{
  display: flex;
  flex-direction: column;
  width: 100%;
 
}
.archSub p{
  /* width: 100%; */
  color: #6C757D !important;
}
.archTitle{
  font-size: 18px;
  color: #343A40;
  font-weight: 600;
}
.archSubText{
  font-size: 14px;
  color: #6C757D;
  font-weight: 400;
}
.empty{
  width: 100%;
  background-color: #ACE7C71A;
  padding: 2rem 0;
  display: block;
}
.financialGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media(max-width:800px){
  .empty{
    display: none;
  }
  .archvTable{
    padding-top: 6rem;
  }
  .mainContainter{
    display: flex;
    flex-direction: column;
    padding: 0 25px;
  }
  .leftContainer{
    height: auto;
    position: relative;
    border-right:none;
    padding: 15px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: none; */
  }
  .filterText{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .filterText img{
    display: block;
    width: 30px;
    height: 30px;
  }
  .filterBox{
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    top: 3rem;
    padding: 1.5rem 20px;
    box-shadow: 2px 3px  5px rgb(186, 184, 184);
    /* border: 1px solid rebeccapurple; */
  }
  .unActive{
    display: none;
  }

  .formGroup {
    width: 100%;
  }
  .formGroup .label{
    color: black;
    margin-top: 16px;
    /* margin-bottom: 28px; */
  }
  .rightContainer{
    width: 100%;
    padding: 0;
  }  
  .archivGco{
    width: 100%;
    border: none;
  }
  .filt{
    color: #6C757D;
    font-size: 20;
    font-weight: 700;
    padding-top: 0;
    /* margin-bottom: 2px; */
  }
  
  .dwnldbtn1{
    margin-top: 28px;
    width: 100%;
    background-color: #2D995F;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .dwnldbtn2{
    padding: 0;
    padding: 4px 10px;
    font-size: 8px;
    
    color: white;
    border: 1px solid #2D995F;
    background-color: #2D995F;
    
    font-weight: 500;
  }
  .archHeader{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #343A40;
    font-weight: 700;
    padding: 10px 0;
    margin-bottom: 0;
  }
  .archLabel{
    font-size: 20px;
    color: #6C757D;
  
  }
  .archContainer{
    width: 100%;
    height: auto;
    border: 1px solid #2D995F;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 28px;
  }
  .archContainerLast{
    border: 1px solid #2D995F;
  }
  .archiveFlex{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .archiveFlexNewFles{
    display: flex;
    justify-content: flex-start !important;
  }
  .archiveFlex img{
    width: 53px;
    height: 53px;
  }
  .webShow{
    display: none;
  }
  .mobileShow{
    display: block;
  }
  .archSub{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .textAdjustSize{
    width: 85%;
  }
  .h5Resize{
    font-size: 10px !important;
  }

  .archSub h5{
    font-size: 12px;
    font-weight: 600;
  }
  .archSub p{
    font-size: 10px;
    margin-bottom: 5px;
  }
  .archTitle{
    font-size: 14px;
    color: #343A40;
    font-weight: 400;
  }
  .archSubText{
    font-size: 12px;
    color: #6C757D;
    font-weight: 400;
  }
  .directions{
    margin-left: 0;
  }
  .directions p{
    font-size: 6px;
    margin: 0 0 0 3.7rem ;
  }
}
