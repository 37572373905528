body {
    overflow-x: hidden;
}

.header {
    display: flex;
    /* font-family: inter; */
    /* justify-content: space-between; */
}
.mdaaaas{
    margin-bottom: 30px;
}

.logoTxt {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin-left: 81px;
    margin-top: 32px;

}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }
  
  .loading-text {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #000000;
    font-family: 'inter', sans-serif;
  }
  
  .no-ministry-text {
    text-align: center;
    font-size: 1.2rem;
    font-family: 'inter', sans-serif;
    margin-top: 5rem;
    color: #000000;
    height: 20vh;
  }
  

.logo {
    width: 76px;
    height: 70px;
    margin-right: 26px;
}

.ogun {
    margin-top: 5px;
    font-weight: 700;
    color: #000000;
}

.opts {
    margin-top: 40px;
    display: flex;
    margin-left: 360px;
    gap: 25px;
    font-weight: 400;
    color: #6C757D;

}

.mdas {
    text-align: center;
    width: 198px;
    height: 46px;
    background-color: #2D995F;
    color: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 45px;
    margin-left: 237px;
}

.mda2 {
    text-align: center;
    width: 198px;
    height: 46px;
    background-color: #ffffff;
    color: #2D995F;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 45px;
    margin-left: 87px;
    position: absolute;
    top: 90%;
}

.rect {
    width: 1519px;
    height: 799px;
}

.rect12 {
    width: 658px;
    height: 601px;
    position: absolute;
    top: 20%;
    left: 52%;
}

.textInside {
    font-size: 96px;
    font-weight: 900;
    color: #ffffff;
    position: absolute;
    top: 28%;
    left: 5%;
}

.txtUnder {
    position: absolute;
    top: 74%;
    color: #ffffff;
    margin-left: 87px;
}

.line {
    width: 1212px;
    height: 257.69px;
    margin-left: 100px;
    margin-top: 70px;
}

.aflex {
    display: flex;
    /* gap: 30px; */
}

.containersUnder {
    background-color: #FBFEFC;
    /* background-color: pink; */
    width: 100%;
    height: 384px;
    border-radius: 8px;
    position: absolute;
    top: 110%;
    margin-left: 137px;
}

.containersUnder2 {
    background-color: #FBFEFC;
    /* background-color: pink; */
    width: 384px;
    height: 384px;
    border-radius: 8px;
    position: absolute;
    top: 110%;
    margin-left: 590px;
}

.containersUnder3 {
    background-color: #FBFEFC;
    /* background-color: pink; */
    width: 384px;
    height: 384px;
    border-radius: 8px;
    position: absolute;
    top: 110%;
    margin-left: 1023px;
}

.icon {
    margin-left: 38px;
    margin-top: 50px;
    width: 85px;
    height: 85px;
}

.invest {
    color: #2D995F;
    font-weight: 700;
    font-size: 36px;
    margin-top: 20px;
    margin-left: 40px;
}

.idk {
    color: #ADB5BD;
    font-weight: 400;
    font-size: 12px;
    margin-top: -30px;
    margin-left: 40px;
}

.info1 {
    width: 590px;
    height: 407px;
    background-color: #E9ECEF;
    filter: brightness(105%);
}

.info2 {
    width: 590px;
    height: 407px;
    background-color: #E9ECEF;
    margin-left: 10px;
    filter: brightness(105%);
}

.info3 {
    width: 590px;
    height: 407px;
    background-color: #2D995F;
    color: #ffffff !important;
    /* filter: brightness(105%); */
}

.population {
    font-weight: 900;
    font-size: 64px;
    margin-left: 81px;
    color: #343A40;
}

.population3 {
    font-weight: 900;
    font-size: 64px;
    margin-left: 81px;
    color: #ffffff;
}

.pop {
    font-weight: 400;
    font-size: 20px;
    color: #6C757D;
    margin-left: 81px;
}

.pop3 {
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    margin-left: 81px;
}

.aflex2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.greenTxt {
    color: #2D995F;
    font-size: 14px;
    font-weight: 700;
}

.notgreen {
    color: #6C757D;
    font-size: 14px;
    font-weight: 700;
}

.iseya {
    font-weight: 800;
    font-size: 350px;
    margin-top: -120px !important;
    margin-left: 98px;
    letter-spacing: 81px;
    color: #D38E34;
    /* font-family: inter; */
}

.underBig {
    color: #6C757D;
    font-weight: 700;
    font-size: 14px;
    /* font-size: 18px; */
    display: flex;
    /* justify-content: space-between; */
    margin-top: -80px;
    margin-left: 100px;
}

.social {
    margin-left: 120px !important;
    white-space: nowrap;
    color: #6C757D;
    font-weight: 700;
    font-size: 14px;
}

.educationTxt {
    margin-left: 140px;
}

.youth {
    margin-left: 150px;
}

.food {
    margin-left: 190px;
}

.aboutGov {
    /* officiL SCREEN SIXE */
    width: 759.5px;
    height: 710px;
    background-color: #ffffff;
    filter: brightness(95%);
}

.govtxt {
    font-weight: 800;
    font-size: 48px;
    font-family: inter;
    margin-left: 60px;
    margin-top: 146px;
}

.lol {
    font-size: 16px;
    color: #6C757D;
    font-family: inter;
    font-weight: 400;
    margin-left: 60px;
}

.dapo {
    width: 759.5px;
    height: 710px;
}

.textUner {
    margin-left: 590px;
    display: block;
}

.ogunTxt {
    margin-top: 208px;
    font-size: 24px;
    font-weight: 700;
    color: #343A40;
}

.ogunTxt2 {
    /* margin-top: 208px; */
    font-size: 64px;
    font-weight: 900;
    color: #343A40;
    font-family: inter;
    margin-top: -25px;
}

.educationImg {
    width: 350px;
    height: 481px;
    /* margin-top: -30px; */
}

.education {
    font-size: 28px;
    font-weight: 700;
    color: #343A40;
    font-family: inter;
    /* margin-top: -110px; */
    margin-left: 142px;
    /* line-height: 10px; */
    position: absolute;
    margin-top: 320px;
}

.culture {
    margin-top: 77px;
    font-size: 28px;
    font-weight: 700;
    color: #343A40;
    font-family: inter;
}

.housing {
    font-size: 28px;
    font-weight: 700;
    color: #343A40;
    font-family: inter;
}

.mda3 {
    text-align: center;
    width: 210px;
    height: 52px;
    background-color: #ffffff;
    color: #2D995F;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    line-height: 50px;
    margin-left: 659.625px;
    font-family: inter;
    margin-top: 50px;
}

.investCOntainer {
    margin-top: -205px;
    width: 1519px;
    height: 703.2px;
    background-color: #227247;
    color: #fff;
}

.investTxt {
    font-weight: 800;
    font-size: 307.65px;
    margin-left: 106.44px;
    letter-spacing: 25px;
}

.instate {
    margin-top: -340px;
    font-weight: 400;
    font-size: 56.256px;
    letter-spacing: 10px;
    margin-left: 520.43px;

}

.tems {
    font-weight: 400;
    font-size: 17.58px;
    margin-left: 459.96px;
    font-family: inter;
    margin-top: -50px;
}

.underInvest {
    display: flex;
    gap: 182.759px;
    margin-top: -80px;
}

.emergency {
    display: block;
    margin-left: 72.078px;

}

.ogunStateTxt {
    font-weight: 700;
    font-size: 28px;
    color: #343A40;
    font-size: 24.612px;
    margin-top: 189.864px;
}

.underogunStateTxt {
    color: #2D995F;
    font-weight: 900;
    font-size: 56.256px;
    margin-top: -20px;
    font-family: inter;
}

.fireService {
    margin-top: 189.864px;
}

.policeService {
    margin-top: 189.864px;
}

.fireServiceTxt {
    font-weight: 700;
    font-size: 24.612;
    color: #343A40;
    font-family: inter;
}

.oni {
    color: #2D995F;
    font-weight: 400;
    font-family: inter;
    margin-top: -10PX;
}

.number {
    font-family: inter;
    color: #6C757D;
    font-size: 31.644px;
    margin-top: -15px;
}

.number2 {
    font-family: inter;
    color: #6C757D;
    font-size: 31.644px;
    margin-top: -20px;
}

.container1 {
    display: flex;
    margin-top: -105px;
}

.news {
    background-color: #FBFEFC;
    /* background-color: pink; */
    margin-top: 30px;
    width: 1519px;
    height: 964px;
    /* display: flex; */
}

.newsTxt {
    color: #343A40;
    font-weight: 700;
    font-size: 36px;
    margin-left: 50%;
    /* margin-top: 10   0px; */
    line-height: 200px;
}

.newsContainer {
    display: block;
    width: 400px;
    height: 614px;
    background-color: #ffffff;
    margin-top: -40px;
    border-radius: 8px;
    margin-left: 174px;
}

.newsContaine {
    display: block;
    width: 400px;
    height: 614px;
    background-color: #ffffff;
    margin-top: -40px;
    border-radius: 8px;
    margin-left: 14px;
}

.dapo2 {
    width: 377px;
    height: 409px;
    margin-left: 10px;
    margin-top: 10px;
}

.launches {
    margin-left: 10px;
    font-weight: 700;
    font-family: inter;
    font-size: 24px;
    white-space: nowrap;
    color: #343A40;
}

.randomTxt {
    font-size: 12px;
    font-weight: 400;
    color: #ADB5BD;
    font-family: inter;
    margin-top: -15px;
    margin-left: 10px;
}

.readmoreTxt {
    color: #2D995F;
    font-family: inter;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    margin-left: 10px;
}

.readMoreButton {
    text-align: center;
    width: 198px;
    height: 46px;
    background-color: #2D995F;
    color: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    margin-left: 675px;
    margin-top: 49px;
    font-family: inter;
}

.footer {
    width: 1519px;
    height: 310px;
    background-color: #343A40;
    color: #ffffff;
    display: flex;
}

.theLogo2Footer {
    width: 122px;
    height: 114px;
    margin-left: 60px;
    margin-top: 50px;
    display: flex;
}

.headerText2Footer {
    font-size: 30px;
    color: #ffffff;
    font-weight: 700;
    margin-left: 60px;
    margin-top: 20px;
}

.quick {
    margin-left: 310px;
    margin-top: 60px;
    font-size: 12px;
    font-family: inter;
    font-weight: 400;
}

.head {
    font-size: 16px;
    font-weight: 700;
    font-family: inter;
    /* line-height: 50px; */
}

.logoTxt2 {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin-left: 81px;
    margin-top: 69px;
    color: #ffffff;

}

.ogun2 {
    margin-top: 5px;
    font-weight: 700;
    color: #ffffff;
    font-family: inter;
}
/* span{
    color: 
    #2D995F;
} */
.Mda_Header {
    background-color: #ffffff;
    /* justify-content: space-between; */
    display: flex;
    align-items: center;
    /* background-color: pink; */
    height: 100px;
    margin-bottom: 60px;
}

.Mda_opts {
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin-left: 360px;
    margin-top: 50px;
    font-weight: 700;
    font-size: 14px;
    color: #6C757D!important;
    font-family: inter;
    padding: none;
    cursor: pointer;
}

.Mda_mainCotainer {
    background-color: #FBFEFC;
    width: "100%";
    margin-top: -50px;
    padding-top: 30px;
}
.active{
    color: green;
  }

.container {
    background-color: #FBFEFC;
    /* background-color: pink; */
    height: 1300px;
    width: 100%;
    margin-top: -50px;
    padding-top: 30px;
    margin-bottom: 50px;
}
.container2 {
    background-color: #FBFEFC;
    /* background-color: pink; */
    height: 3250px;
    width: 1527px;
    margin-top: -50px;
    padding-top: 30px;
}
.ministry {
    width: 170px;
    height: 170px;
    margin-top: 15px;
    margin-left: 15px;
}

.space-top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
}

.mda_container {
    display: flex;
    background-color: #ffffff;
    cursor: pointer;
    /* padding-bottom: 20px; */
    /* margin-top: 7rem; */
    /* align-items: center; */
    /* margin-top: 60px; */
    margin-left: 180px;
    width: 1100px;
    /* padding-top: 60px; */
    height: 208px;
    border-radius: 20px;
    /* position: absolute;  */
    /* top: 170px;  */
    /* margin-bottom: 50px; */
}

.endded {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    width: 100%;
  }
  .bottom-ended{
    display: flex;
    margin-bottom: 80px;
    justify-content: center;
    align-items: center;

  }

.mda_container2 {
    display: flex;
    background-color: #ffffff;
    margin-top: 30px;
    margin-left: 180px;
    width: 1100px;
    /* padding-top: 60px; */
    height: 208px;
    border-radius: 20px;
}

.textInMda {
    margin-left: 25px;
    font-family: inter;
}

.textInMdaMinistry {
    color: #2D995F;
    font-size: 24px;
    font-weight: 700;
    padding-top: 30px;
}

.textInMdaunder {
    font-size: 12px;
    color: #6C757D;
    font-weight: 400;
    -webkit-line-clamp: 3;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.viewMinistry {
    font-size: 12px;
    color: #2D995F;
    margin-top: 30px;
}



button {
    padding: 0;
    background: none;
    border: none;
    font-family: inherit;
    font-weight: normal;
    font-size: inherit;
    cursor: pointer;
    outline: none;
    font-family: inter;
    color: #6C757D;
    font-weight: 700;
    text-align: start;
    font-family: inter;
    font-weight: normal;
}

.depImg {
    width: 170px;
    height: 190px;
    margin-top: 10px;
    margin-left: 10px;
}

@media(max-width:800px){
    .Mda_mainCotainer{
        width: 100%;
    }
    .mda_container {
        display: flex;
        background-color: #ffffff;
        flex-direction: row;
        cursor: pointer;
        margin-left: 10px;
        width: 350px;
        /* justify-content: center; */
        align-items: center;
        /* padding-top: 60px; */
        height: 120px;
        border-radius: 5px;
        /* position: absolute;  */
        /* top: 170px;  */
        /* margin-bottom: 50px; */
    }
    .ministry {
        width: 80px;
        height: 80px;
        object-fit: block;
        margin-top: 15px;
        margin-left: 15px;
    }
    .textInMdaMinistry {
        color: #000000;
        font-size: 15px;
        font-weight: 700;
        /* padding-top: 30px; */
    }
    .textInMdaunder {
      display: none;
    }
    .viewMinistry {
        font-size: 12px;
        color: #2D995F;
      margin-top: -10px;
    }
}