.footerbody {
    background-color: #343A40;
    width: 100%;
    height: 332.78px;
    display: flex;
    justify-content: space-around;
    font-family: 'inter';
    align-items: center;
    padding-left: -185px;
}

.footrlogo {
    width: 160.51px;
    height: 48.94px;
    margin-top: -195px;
}

.lnks {
    font-size: 15.06px;
    color: white;
    font-weight: 700;
}

.subtxtlnks {
    margin-top: 25px;
}
.subtxtlnks p {
    color: white;
    font-size: 13.55px;
    font-weight: 400;
    margin-top: 24.16px;
    gap: 40px;
}

